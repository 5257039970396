
@import "stylesheets/scout/variables";

:deep(.missing-photo.small) {
  font-size: 13px;
  font-weight: 600;
  box-shadow: none;
}

.client-icon :deep(.missing-photo) {
  color: $white;
  background-color: $k-blue;
}

.lawyer-icon :deep(.missing-photo) {
  color: $gray-6;
  background-color: $k-darker-gray;
}
