
@import "stylesheets/scout/variables";

.lawyer-row {
  padding: 15px 20px;

  &:not(:last-child) {
    border-bottom: 1px solid $medium-gray;
  }
}
