
@import "stylesheets/globals/variables";

.base-icon {
  &.gray {
    fill: $k-dark-gray;
  }

  &.green {
    fill: $k-green;
  }
}

.search-list-item-container {
  &:hover {
    .hoverable {
      fill: $k-blue;
    }
  }
}
