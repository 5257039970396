
@import "stylesheets/scout/variables";

.field-group {
  padding: 20px;
  border: 1px solid $k-light-gray;
  border-radius: $border-radius-large;
}

.remove-btn {
  color: $k-darker-gray;
  font-weight: 600;
}
