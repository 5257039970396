
@import "stylesheets/scout/variables";

.date-picker-container {
  max-width: 300px;
}

.styled-date-picker {
  position: absolute;
  z-index: 1000;
}

.button-column {
  @media (min-width: $screen-sm-min) {
    width: 23%;
  }

  @media (min-width: $screen-lg-min) {
    width: 18%;
  }
}

.files-column {
  @media (min-width: $screen-sm-min) {
    width: 77%;
  }

  @media (min-width: $screen-lg-min) {
    width: 82%;
  }
}

.file-list {
  margin-bottom: -10px;

  .file-item {
    display: inline-block;
    margin-bottom: 10px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.width-max-content {
  width: max-content;
}

.a-form .form-control[readonly] {
  background: $white;
}
