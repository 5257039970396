
@import "stylesheets/scout/variables";

.styled-date-picker {
  position: absolute;
  z-index: 1000;
}

.a-form .form-control[readonly] {
  background: $white;
}
