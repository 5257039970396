
@import "stylesheets/scout/variables";

.pill-switcher-container {
  display: flex;
  justify-content: center;
  border-radius: 20px;
}

.option {
  color: $k-blue;
  font-size: 13px;
  font-weight: 700;
  padding: 8px 15px;
  text-align: center;
  width: fit-content;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    background: $k-blue;
    color: $white;
  }
}

.option-left {
  border-bottom: 1px solid $k-blue;
  border-left: 1px solid $k-blue;
  border-top: 1px solid $k-blue;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  padding-left: 20px;
}

.option-between {
  border-bottom: 1px solid $k-blue;
  border-left: 1px solid $k-blue;
  border-top: 1px solid $k-blue;
}

.option-right {
  border: 1px solid $k-blue;
  padding-right: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
