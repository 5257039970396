
@import "stylesheets/scout/variables";

.border-gradiant {
  background: linear-gradient($k-purple, $k-blue);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  border-top-left-radius: $border-radius-large;
  border-bottom-left-radius: $border-radius-large;
}

.feedback-icon {
  width: 60px;
  height: 66px;
}
