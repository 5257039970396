
@import "stylesheets/globals/variables";
@import "stylesheets/globals/mixins";

.uiv-dropdown:deep {
  .dropdown-menu {
    @include k-shadow;
    min-width: 210px;
    width: 100%;
    margin: 10px 0 0;
    padding: 0;
    border: 0;
    border-radius: $border-radius-large;
    font-size: 13px;
    font-weight: 400;

    .menu-item {
      padding: 0;
      color: $k-darker-gray;
      border-bottom: 1px solid $k-light-gray;

      &:first-child, &:first-child a {
        border-radius: $border-radius-large $border-radius-large 0 0;
      }

      &:last-child, &:last-child a {
        border-radius: 0 0 $border-radius-large $border-radius-large;
        border-bottom: 0;
      }

      a {
        @include text-ellipsis;
        width: 100%;
        padding: 20px;
        font-weight: 600;
        line-height: 20px;
        border-bottom: 0;
        color: $k-darker-gray;

        &:hover {
          text-decoration: none;
          background-color: $bg-color;
        }

        svg {
          margin-right: 15px;
        }
      }

      section.uiv {
        display: flex;
      }
    }
  }

  &.compact {
    .dropdown-menu {
      a {
        padding: 10px 20px;
      }

      section.uiv {
        display: flex;
      }
    }
  }
}
