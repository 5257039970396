
@import "stylesheets/scout/lists";

:deep {
  & > ul, & > ol {
    @extend %headed-list;

    list-style-type: disc;
  }

  & > ul > li > ul, & > ol > li > ol {
    @extend %flattened-list;
    @extend %parenthesized-list;

    margin-left: 3px;
    display:    inline;
    font-style: italic;
  }

  & > ol > li > ol {
    @extend %decimal-list;

    margin-left: 3px;
  }
}
