
@import "stylesheets/globals/variables";

hr {
  margin-top: 10px;
  border-color: $medium-gray;
}

.gray-box {
  background-color: $gray-10;
  padding: 20px;
  border: 1px solid $gray-7;
  border-radius: 6px
}

.row.equal-height {
  display: flex;
  flex-wrap: wrap;
}

.col-sm-6.d-flex {
  display: flex;
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.equal-height {
  align-items: stretch;
}

.purple-circle {
  background: $purple-9;
  border-radius: 50%;
  height: 56px;
  width: 56px;
}

.svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-svg {
  width: 32px;
  height: 32px;
  stroke: $purple-5;
}
