
@import "stylesheets/scout/variables";

.quote-form {
  @media (min-width: $screen-sm-min) {
    margin-top: -10px;
  }

  @media (min-width: $screen-md-min) {
    margin-top: -20px;
  }
}

.update-text {
  position: relative;
  padding-left: 11px;
}

.dot {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  background-color: $p-yellow;
  border-radius: 50%;
  left: 0;
}

.messaging-button {
  top: -25px;
  right: -15px;

  @media (min-width: $screen-sm-min) {
    top: 0;
  }
}
