
@import "stylesheets/scout/variables";

.icon-button {
  &:disabled, &:disabled .base-icon {
    opacity: 1;
  }
}

.selected {
  color: $scout-green;
  fill: $scout-green;
  background-color: $green-10;
  border-color: $scout-green;

  svg {
    fill: $scout-green;
  }
}
