
@import "stylesheets/globals/variables";

.tab-titles {
  @media (min-width: 768px) {
    display: flex;
  }

  .tab-title {
    width: 100%;
    flex: 1 0 0%;
    padding: 20px 5px;
    height: 60px;
    border: 0;
    border-bottom: 1px solid $k-light-gray;
    background: transparent;
    color: $k-dark-gray;
    font-size: 11px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &:first-child {
      border-top-left-radius: $border-radius-large;
      border-top-right-radius: $border-radius-large;
    }

    @media (min-width: 768px) {
      &:first-child {
        border-top-right-radius: 0;
      }

      &:last-child {
        border-top-right-radius: $border-radius-large;
      }
    }

    &:hover {
      background-color: $bg-color;
      color: $k-darker-gray;
    }

    &.selected-tab {
      padding-bottom: 17px;
      border-bottom: 3px solid $k-blue;
      color: $k-darker-gray;
    }
  }
}
