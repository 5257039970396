
@import "stylesheets/globals/variables";

.experience-btn {
  display: flex;
  width: 146px;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid $purple-5;
  border-radius: 50px;
  background-color: transparent;
  color: $purple-5;
  font-weight: 700;
  outline: none;
  appearance: none;

  &:hover, &.selected {
    background-color: rgba($purple-5, .3);
  }

  .base-icon {
    width: 32px;
    height: 32px;
    stroke: $purple-5;
  }
}
