
@import "stylesheets/scout/variables";

.review-reminder {
  padding: 20px;
  border: 1px solid $k-gray;
  border-radius: $border-radius-large;
}

.type-text {
  padding: 3px 8px;
  border-radius: $border-radius-small;
  color: $white;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;

  &.green-bg {
    background: $k-green;
  }

  &.purple-bg {
    background: $k-purple;
  }

  &.red-violet-bg {
    background: $k-red-violet;
  }

  &.red-pink-bg {
    background: $k-red-pink;
  }
}

.prompt {
  margin-top: 12px;
  font-weight: 600;
  line-height: 1.4;
}

.buttons-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.review-button-container {
  margin-right: 15px;
  width: 80px;
}

.review-reminder-review-button {
  padding: 5px 15px;
  border: 1px solid $k-blue;
  border-radius: $border-radius-large;
  color: $k-blue;
  background: transparent;
  font-weight: 700;
  text-transform: uppercase;

  &:hover, &:active {
    background-color: $k-blue;
    color: $white;
  }
}
