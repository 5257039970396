
.proposal-nav-buttons {
  margin-left: -20px;
  margin-right: -20px;

  .lawyer-nav-button-container {
    padding: 0 20px;
  }
}

.nv-button-white-purple {
  text-align: left;
  height: 70px;
  text-transform: none;

  .main-text {
    line-height: 20px;
    text-transform: capitalize;
  }

  &.active {
    .main-text {
      font-weight: 600;
    }
  }
}
