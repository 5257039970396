
@import "stylesheets/scout/variables";

.review-reminders-container {
  padding: 20px;
}

.review-reminder-thirds {
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: 32%;

    &:not(:last-child) {
      margin-right: 2%;
    }
  }
}

.caret-container {
  width: 20px;

  &:hover {
    cursor: pointer;
  }

  .arrow {
    height: 20px;
    width: 20px;
    fill: $k-darker-gray;
  }

  &.lighter {
    .arrow {
      opacity: .5;
    }

    &:hover {
      cursor: default;
      pointer-events: none;
    }
  }
}

.pagination-text {
  user-select: none;
}
