
@import "stylesheets/globals/variables";

.lawyer-note {
  padding: 15px 30px 15px 15px;
  background: $light-purple;
  border-radius: $border-radius-large;
}

.project-manager-row {
  align-items: flex-start;
  column-gap: 15px;
}
