
@import "stylesheets/globals/variables";

.profile-header-container {
  padding-bottom: 30px;

  @media (min-width: $screen-sm-min) {
    display: flex;
    column-gap: 10px;
    justify-content: space-between;
    margin-bottom: 30px;
    border-bottom: 1px solid $k-light-gray;
  }
}

.profile-header-buttons {
  display: flex;
  flex: 1;
  column-gap: 13px;

  @media (min-width: $screen-sm-min) {
    column-gap: 10px;
  }

  .action-bar-button {
    padding: 10px 29px;
    border: 1px solid $k-light-gray;
    border-radius: 6px;

    @media (min-width: $screen-sm-min) {
      flex: 1;
      padding: 10px;
    }
  }
}
