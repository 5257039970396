
@use "stylesheets/globals/colors";

.purple-link {
  color: colors.$purple-5;

  &:hover, &:focus {
    color: colors.$purple-5;
  }
}
