
@import "stylesheets/scout/variables";

.quote-section {
  @media (min-width: $screen-sm-min) {
    padding-left: 20px;
  }

  @media (min-width: $screen-md-min) {
    padding-left: 30px;
  }

  @media (min-width: $screen-lg-min) {
    padding-left: 40px;
  }
}

.dot {
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  background-color: $p-yellow;
  border-radius: 50%;
  margin-left: 5px;
}

.messaging-button-container {
  height: 60px;
}

.quote-actions {
  :deep( > :not(:last-child)) {
    margin-bottom: 10px;
  }
}
