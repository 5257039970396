
.list-header {
  padding: 10px 20px 10px 30px;

  [class*="col-"] {
    .base-icon {
      margin-bottom: 2px;
    }
  }
}

.d-flex-not-xs.wrap {
  flex-wrap: wrap;
}

.inline-block-only-xs {
  display: inline-block;

  @media (min-width: 768px) {
    display: flex;
  }
}
