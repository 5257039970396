
@import "stylesheets/scout/variables";

.inline-confirmation {
  padding: 20px 30px 20px 26px;
  border: 1px solid $medium-gray;
  border-left: 4px solid $k-blue;
  border-radius: $border-radius-large;
  background-color: $bg-color;
}

.buttons-container {
  button {
    padding-right: 30px;
    padding-left: 30px;
  }
}
