%flattened-list {
  padding: 0;

  ul, ol, li, p, div {
    display: inline;
    padding: 0;
  }

  li {
    &:not(:last-of-type)::after {
      content: ", ";
    }

    & > ol::before, & > ul::before {
      content: ", ";
    }
  }
}

%headed-list {
  padding: 0;

  & > li > p {
    margin:  0 3px 0 0;
    display: inline;
  }

  & > li:first-of-type {
    display: block;

    & > p { display: block; }
  }

  & > li:not(:first-of-type) {
    margin-left: 15px;
  }
}

%parenthesized-list {
  &::before {
    display: inline-block;
    content: "(";
  }

  &::after {
    display: inline-block;
    content: ")";
  }
}

%roman-numeral-list {
  counter-reset: roman-numeral-list;

  li {
    counter-increment: roman-numeral-list;

    &::before {
      display: inline-block;
      content: counter(roman-numeral-list, lower-roman) ".";
      margin-right: 3px;
    }
  }
}

%decimal-list {
  counter-reset: decimal-list;

  li {
    counter-increment: decimal-list;

    &::before {
      display: inline-block;
      content: counter(decimal-list, decimal) ":";
      margin-right: 3px;
    }
  }
}
