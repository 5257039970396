
@import "stylesheets/scout/variables";

.lawyer-container {
  border: 1px solid $medium-gray;
  border-radius: $border-radius-base;
  background-color: $white;
  padding: 12px 20px;
}

.minus-icon {
  cursor: pointer;

  .base-icon {
    fill: $pill-gray;
  }

  &:hover .base-icon {
    fill: $k-darker-gray;
  }
}

.disable-lawyer-card {
  background-color: $k-lighter-gray;
}
