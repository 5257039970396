
@import "stylesheets/scout/variables";

.scrollable-list {
  max-height: 271px;
  overflow-y: auto;
}

.highlighted {
  :deep(strong) {
    color: $k-blue;
  }
}

.blue {
  color: $k-blue;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}
