
@import "stylesheets/globals/variables";

ul {
  list-style: none;
  padding-inline-start: 0;

  li {
    display: flex;
    column-gap: 7px;

    .base-icon {
      fill: $k-dark-gray;
      width: 16px;

      &.success {
        fill: $k-green;
      }
    }
  }
}

:deep(.a-form) {
  .check-option input[type="checkbox"] {
    top: 1px;

    ~ .check {
      top: 1px;
      width: 16px;
      height: 16px;
      border: 1px solid $gray-3;

      &:hover {
        border-color: $purple-5;
      }
    }
  }

  .check-option input:checked ~ .check {
    border-color: $purple-5;
    background-color: $purple-5;
  }

  .check-option input[type="checkbox"]:checked ~ .check {
    svg {
      position: relative;
      top: -4px;
      left: -2px;
      fill: white;
    }
  }

  .check-option input[type="checkbox"]:not(:checked) ~ .check {
    svg {
      display: none;
    }
  }
}

:deep(i.counter) {
  font-size: 13px;
}
