
@import "stylesheets/scout/variables";

.rating-circle {
  position: relative;
  width: 170px;
  height: 170px;

  .layers {
    border-radius: 50%;

    &:not(.range-outer) {
      position: absolute;
      left: 50%;
      top: 50%;
    }

    &.range-outer {
      width: 170px;
      height: 170px;
      transform: rotate(225deg);
      background: $grey-3;
    }

    &.rating-base {
      width: 150px;
      height: 150px;
      transform: translate(-50%, -50%) rotate(225deg);
      background: $k-blue;
      clip-path: path("M12.78,81.25c0-3.45178-2.79822-6.25-6.25-6.25s-6.25,2.79822-6.25,6.25h-.28L0,150h150v-150h-68.75v.28C77.79822,0.28,75,3.07822,75,6.53s2.79822,6.25,6.25,6.25v4.95775L17.73775,81.25h-4.95775ZM81.25,17.73775M17.73775,81.25");
      z-index: 0;
    }

    &.rating-mask {
      width: 126px;
      height: 126px;
      transform: translate(-50%, -50%);
      background: $white;
      z-index: 1;
    }

    &.range-inner {
      width: 126px;
      height: 126px;
      transform: translate(-50%, -50%) rotate(225deg);
      background: $grey-3;
      z-index: 2;
    }

    &.inset-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 104px;
      height: 104px;
      transform: translate(-50%, -50%);
      background: $white;
      z-index: 3;
    }
  }

  .dot-container, .diamond-container {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 3;
  }

  .diamond-container-tooltip, .dot-container-tooltip {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 10px;
    left: 10px;
    pointer-events: none;
    z-index: 5;
  }

  .dot {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $k-blue;
    border: 4px solid $white;
    border-radius: 50%;

    &.purple-gradient {
      background-color: $k-blue;
    }

    &.blue-gradient {
      background-color: $k-purple;
    }
  }

  .diamond {
    width: 10px;
    height: 10px;
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: $pill-gray;
    border-radius: 1px;
    z-index: 0;
  }

  .diamond-tooltip-overlay {
    width: 10px;
    height: 10px;
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: transparent;
    border-radius: 1px;
    z-index: 7;
    pointer-events: auto;
  }

  .dot-tooltip-overlay {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border-radius: 50%;
    z-index: 7;
    pointer-events: auto;
  }
}
