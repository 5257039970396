
@import "stylesheets/globals/variables";

.inline-notification {
  width: 100%;
  padding: 10px;
  border-radius: $border-radius-large;
  word-break: break-word;

  @media (min-width: $screen-sm-min) {
    padding: 10px 20px 10px 14px;
    display: flex;
    column-gap: 12px;
  }

  svg {
    flex: 0 0 18px;
  }

  &.error {
    background: $red-10;
    border-left: 6px solid $red-4;

    svg {
      fill: $red-4;
    }
  }

  &.success {
    background: $green-10;
    border-left: 6px solid $green-4;

    svg {
      fill: $green-4;
    }
  }

  &.info, &.warning {
    background: $yellow-10;
    border-left: 6px solid $yellow-7;

    svg {
      fill: $yellow-7;
    }
  }
}
