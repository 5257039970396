
@import "stylesheets/scout/variables";

.remove-btn {
  align-items: center;
  color: $k-darker-gray;
  display: flex;
  justify-content: right;
}

.expanded {
  border-left: 4px solid $k-blue;
}

// overrides for app/javascript/src/stylesheets/scout/forms.scss
.field-group {
  padding: 20px;
  border-bottom: none;
  border-radius: 0;
}

.top-rounded-corners {
  border-radius: 4px 4px 0px 0px;
}

.last-border-corners {
  border-radius: 0px 0px 4px 4px;
  border-bottom: 1px solid $k-light-gray;
}
