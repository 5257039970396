
@import "stylesheets/scout/variables";

.quote-container {
  border: 1px solid $medium-gray;
  border-radius: $border-radius-base;
  padding: 10px 15px;
  border-left: 3px solid $k-purple;
}

.no-quotes {
  padding: 21px 15px;
  text-align: center;
  border-radius: $border-radius-large;
  background-color: $k-lighter-gray;
  color: $pill-gray;
}
