// darkest
$darkblue-1: #080e54;
$darkblue-2: #0a1370;
$darkblue-3: #162298;
$darkblue-4: #2533b4;
$darkblue-5: #3846c8;
$darkblue-6: #5461d8;
$darkblue-7: #7a83df;
$darkblue-8: #a3a6e8;
$darkblue-9: #dddef0;
$darkblue-10: #f3f3fc;
// lightest

// darkest
$blue-1: #00284d;
$blue-2: #003f78;
$blue-3: #0056a3;
$blue-4: #0069c7;
$blue-5: #2588e1;
$blue-6: #52a8f4;
$blue-7: #86c2f8;
$blue-8: #aad4fa;
$blue-9: #d8e7f4;
$blue-10: #eff7ff;
// lightest

// darkest
$purple-1: #240042;
$purple-2: #460059;
$purple-3: #5d0375;
$purple-4: #780096;
$purple-5: #9213b2;
$purple-6: #b148ca;
$purple-7: #ca7cdd;
$purple-8: #dfabeb;
$purple-9: #efe0f2;
$purple-10: #faf2fb;
// lightest

// darkest
$pink-1: #5c0044;
$pink-2: #740055;
$pink-3: #880465;
$pink-4: #a1117b;
$pink-5: #af2e8d;
$pink-6: #c34da4;
$pink-7: #cd72b5;
$pink-8: #eba7d9;
$pink-9: #efd7e9;
$pink-10: #fbf1f8;
// lightest

// darkest
$gray-1: #333;
$gray-2: #535354;
$gray-3: #767676;
$gray-4: #999;
$gray-5: #b8b8b8;
$gray-6: #ccc;
$gray-7: #ddd;
$gray-8: #eee;
$gray-9: #f6f6f6;
$gray-10: #f9f9f9;
// lightest

// darkest
$red-1: #450019;
$red-2: #670126;
$red-3: #990037;
$red-4: #cd034c;
$red-5: #e42369;
$red-6: #ee4b86;
$red-7: #f4699b;
$red-8: #ff89b4;
$red-9: #ffbcd5;
$red-10: #ffe9f1;
// lightest

// darkest
$green-1: #00391a;
$green-2: #005326;
$green-3: #006d32;
$green-4: #00843d;
$green-5: #009c48;
$green-6: #35b06c;
$green-7: #63c38f;
$green-8: #85ddac;
$green-9: #c8ecd8;
$green-10: #ecfdf4;
// lightest

// darkest
$yellow-1: #5a4800;
$yellow-2: #715a00;
$yellow-3: #957700;
$yellow-4: #b48f00;
$yellow-5: #cea500;
$yellow-6: #e4b700;
$yellow-7: #f0ca33;
$yellow-8: #ffdc51;
$yellow-9: #ffea99;
$yellow-10: #fff9e2;
// lightest

// darkest
$orange-1: #be422d;
$orange-2: #d35943;
$orange-3: #ff9a51;
$orange-4: #ffc296;
$orange-5: #ffdec6;
// lightest

// darkest
$newblue-1: #00245b;
$newblue-2: #003483;
$newblue-3: #0043a8;
$newblue-4: #0052cd;
$newblue-5: #005de9;
$newblue-6: #1573ff;
$newblue-7: #4590ff;
$newblue-8: #95bfff;
$newblue-9: #c7dcfb;
$newblue-10: #e9f2ff;
// lightest
