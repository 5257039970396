
@import "stylesheets/scout/variables";

hr {
  margin-top: 15px;
  margin-bottom: 15px;
  border-color: $medium-gray;
}

.text-right-md {
  margin-top: 5px;

  @media (min-width: 992px) {
    margin-top: 0;
    text-align: right;
  }
}
