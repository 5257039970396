
@import "stylesheets/scout/variables";

.checkmark {
  margin-top: 16px;
  width: 20px;
  height: 20px;
  fill: $white;
}

.circle {
  height: 50px;
  width: 50px;
}
