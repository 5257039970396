
@import "stylesheets/globals/variables";

.step-header {
  @media (min-width: $screen-sm-min) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.step-item {
  display: none;
  padding-right: 5px;
  cursor: pointer;

  @media (min-width: $screen-sm-min) {
    display: inline-block;
    padding-left: 5px;
    padding-bottom: 15px;
  }

  .stepper-label-text {
    font-size: 15px;
    font-weight: 600;

    &.invalid {
      color: $k-red;
    }
  }

  &.selected {
    display: inline-block;
    border-bottom: 4px solid $purple-5;
    padding-bottom: 11px;
  }
}

.add-spacer-after {
  position: relative;

  &::after {
    @media (min-width: $screen-sm-min) {
      content: "";
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      left: calc(100% + 15px);
      height: 1px;
      background: $k-gray;
      // this is the calculation for the space between objects that have
      // display:flex and justify-content: space-between.
      width: calc((var(--step-header-width) - var(--step-items-sum)) / (var(--number-of-steps) - 1));
    }
  }
}

.stepper-label {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  color: $white;
  border-radius: 50%;
  font-weight: 700;
  text-align: center;
  line-height: 21px;

  &.status-active {
    display: inline-block;
    background: $purple-5;
  }

  &.status-incomplete {
    background: $pill-gray;

    &.invalid {
      background: $k-red;
    }
  }

  &.status-complete {
    background: $purple-5;

    .base-icon {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 14px;
      height: 14px;
      fill: $white;
    }

    &.invalid {
      background: $k-red;
    }
  }
}
