
ul {
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0;
  text-align: left;
  list-style-type: none;

  li {
    line-height: 16px;
    margin-bottom: 10px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 768px) {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;

    &.column-count-1 { column-count: 1; }
    &.column-count-2 { column-count: 2; }
  }
}
