
@import "stylesheets/scout/variables";

.name-plate.small-plate {
  padding: 10px;
  border: 1px solid $medium-gray;
  border-radius: $border-radius-large;

  &:hover, &.selected-firm {
    background: $k-lighter-gray;
    border-color: $k-dark-gray;
  }

  &.pending-response {
    border-left: 3px solid $scout-gray;
  }

  &.quote-received {
    border-left: 3px solid $high-match-score-blue;
  }

  &.declined {
    border-left: 3px solid $scout-red;
  }

  &.marketplace {
    border-left: 3px solid $k-purple;
  }

  .firm-name {
    font-weight: 600;
    line-height: 1.2;
  }

  &.selected-firm .firm-name {
    font-weight: 700;
  }
}

.missing-photo {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $k-light-gray;
  box-shadow: 0 0 8px 0 rgba($black, .1);
  font-size: 17px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  cursor: default;
  user-select: none;
  vertical-align: middle;
}

.icon-wrapper {
  position: relative;
  width: 22px;

  .base-icon {
    width: 18px;
    height: 18px;
    fill: $k-dark-gray;
  }

  &::after {
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    top: 0;
    right: -4px;
    background-color: $scout-red;
    border-radius: 50%;
  }
}
