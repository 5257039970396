
@import "stylesheets/scout/variables";

.flex {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: $screen-sm-min) {
    justify-content: flex-end;
  }
}

.note {
  width: 265px;
  color: $k-dark-gray;
  line-height: 1.4;
}

.viewing-as-container {
  background: $k-blue;

  @media (min-width: $screen-sm-min) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text {
    color: $white;

    @media (min-width: $screen-sm-min) {
      width: 70%;
    }
  }

  .button {
    display: inline-block;
    padding: 13px 30px;
    background: $white;
    border-radius: $border-radius-large;
    color: $k-darker-gray;
    line-height: 18px;
    font-weight: 700;
    text-transform: uppercase;

    @media (min-width: $screen-sm-min) {
      padding: 11px 30px;
    }

    &:hover {
      text-decoration: none;
      background-color: $k-lighter-gray;
    }
  }
}
