
@import "stylesheets/globals/variables";

.color-purple {
  color: $wave-end-purple;
}

:deep(.color-tag) {
  white-space: nowrap;
  padding: 3px 6px;
  font-size: 13px;
}

.opps-hr {
  border-color: $medium-gray;

  &.top {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  &.bottom {
    margin-top: 15px;
    margin-bottom: 30px;
  }
}

.opportunity-nav-container {
  padding: 20px;
  background: $white;
  border-radius: $border-radius-large;

  @media (min-width: $screen-sm-min) {
    padding: 20px 30px;
  }

  @media (min-width: $screen-md-min) {
    padding: 20px 50px;
  }
}

.opportunity-content-container {
  padding: 20px;
  background: $white;
  border-radius: $border-radius-large;

  @media (min-width: $screen-sm-min) {
    padding: 30px;
  }

  @media (min-width: $screen-md-min) {
    padding: 50px;
  }
}

.nav-btns-container {
  @media (min-width: $screen-sm-min) {
    width: 200px;
  }
}

.opportunity-info-col {
  padding-right: 10px;
  width: calc(100% / 6);

}

.icon-container {
  margin-right: 8px;
}

.apply-btn {
  @media (min-width: $screen-sm-min) {
    width: 230px;
  }
}

.join-mp-button {
  @media (min-width: $screen-sm-min) {
    width: 310px;
  }
}
