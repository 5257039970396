
@import "stylesheets/scout/variables";

$border-button-color: #e5e9ec;

.editor {
  .toolbar {
    padding: 15px;
    border: 1px solid $k-gray;
    border-top-left-radius: $border-radius-large;
    border-top-right-radius: $border-radius-large;
    background-color: #f0f4f6;

    .button {
      width: 30px;
      height: 30px;
      border: 1px solid $border-button-color;
      border-radius: $border-radius-base;
      background-color: $white;

      &:hover {
        border: 1px solid $k-blue;
      }

      &.is-active {
        border: 1px solid $k-blue;
      }

      &:disabled {
        &:hover {
          border: 1px solid $border-button-color;
        }

        svg {
          opacity: .2;
        }
      }

      svg {
        display: block;
        width: 14px;
        height: 14px;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }

  .content {
    border: 1px solid $k-gray;
    border-top: none;
    border-bottom-left-radius: $border-radius-large;
    border-bottom-right-radius: $border-radius-large;
    padding: 15px;
  }
}

:deep(.edit-area) {
  line-height: 20px;
  outline: none;
  min-height: 30px;

  ul, ol {
    margin-top: 0;
    margin-bottom: 9.5px;
    padding-inline-start: 16px;
  }

  p.is-editor-empty:first-child::before {
    color: $k-dark-gray !important;
    font-size: 13px;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}
