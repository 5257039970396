
@import "stylesheets/scout/variables";

.tag-list-item {
  .remove-icon {
    display: block;
    width: 8px;
    height: 8px;
    margin-left: 8px;
    fill: $white;
  }

  &.clear {
    border-color: $medium-gray;

    .remove-icon {
      height: 16px;
      width: 16px;
      fill: $gray-5;

      &:hover {
        fill: $gray-4;
      }
    }
  }
}

.list-wrapper {
  display: flex;
  max-width: fit-content;
  align-items: center;
}
