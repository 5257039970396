
.rendering-text {
  line-height: 1.4;
}

.button {
  background: transparent;
  border: 0;
  padding: 0;
}
