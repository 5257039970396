
@import "stylesheets/globals/variables";

.rate-list {
  .rate-row {
    border-bottom: 1px solid $k-light-gray;

    &:last-child {
      border-bottom: 0;
    }

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 1.3fr repeat(3, .9fr);
      grid-template-rows: 1fr;
      border-bottom: 0;

      .rate-cell:not(:last-child) {
        padding-right: 5px;
      }

      .rate-cell:not(:first-child) {
        padding-left: 5px;
      }

      &.header .rate-cell {
        padding-top: 0;
      }

      &:last-child .rate-cell {
        padding-bottom: 0;
      }
    }

    &.header {
      font-size: 11px;
      font-weight: 600;
    }

    .rate-cell {
      padding-top: 5px;
      padding-bottom: 5px;
      word-break: break-word;
    }
  }
}
