
.wired-box .box-content {
  padding: 20px 30px;
}

:deep(.summary) {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  row-gap: 20px;

  .canvas-container .doughnut-chart {
    position: relative;
  }

  .legend {
    width: 100px;

    ul {
      margin: 0;
    }

    .bullet-container {
      width: 20px;

      .bullet {
        width: 8px;
        height: 8px;
      }
    }
  }
}

.inset {
  width: 116px;
  height: 116px;
  line-height: 1.2;
  position: absolute;
  top: 0;
}
