
ul {
  list-style: none;
  padding-left: 20px;
  margin-bottom: 0;

  li:not(:last-of-type) {
    margin-bottom: 8px;
  }
}
