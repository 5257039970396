
@import "stylesheets/scout/variables";

.links-container {
  margin-bottom: 10px;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 15px;
  }
}

.header-container {
  margin-bottom: 10px;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 30px;
  }
}

.medium-page-heading {
  display: block;
  height: 60px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  @media (min-width: $screen-sm-min) {
    height: 30px;
    -webkit-line-clamp: 1;
  }
}

.rfq-comparison-grid {
  display: grid;
  max-height: calc(100vh - 250px);
  width: fit-content;
  max-width: 100%;
  border: 1px solid $gray-6;
  background-color: $white;
  border-collapse: collapse;
  overflow-x: scroll;
  overscroll-behavior: none;
  grid-template-columns: 300px repeat(v-bind(quotesCount), 360px);

  @media (min-width: $screen-sm-min) {
    max-height: calc(100vh - 215px);
  }

  @media (min-width: $screen-md-min) {
    max-height: calc(100vh - 155px);
  }

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: $bg-color;

    &:vertical {
      border-left: 1px solid $gray-6;
    }

    &:horizontal {
      border-top: 1px solid $gray-6;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: $gray-6;
    -webkit-border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    background: $k-light-gray;
    border-top: 1px solid $gray-6;
    border-left: 1px solid $gray-6;
  }

  @supports (not selector(::-webkit-scrollbar)) {
    scrollbar-width: thin;
    scrollbar-gutter: stable;
    scrollbar-color: transparent;
  }

  thead, tbody, tr {
    display: contents;
  }

  th, :deep(td) {
    padding: 15px;
    border-bottom: 1px solid $gray-6;
    border-left: 1px solid $gray-6;
    background-color: $white;
    font-weight: normal;

    &:first-child {
      background-color: $bg-color;
      font-weight: 700;
      border-left: 0;
    }
  }

  tr:last-child :deep(td) {
    border-bottom: 0;
  }

  th {
    z-index: 1;
  }

  th.header-cell {
    position: sticky;
    top: 0;
    height: 40px;
    padding: 10px;
    background-color: $blue-10;
    text-transform: uppercase;
  }

  th.firm-detail-cell {
    position: sticky;
    top: 40px;
    height: 170px;
    background-color: $white;

    &:first-child {
      background-color: $bg-color;
    }

    .firm-name-container {
      max-height: 42px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      line-height: 21px;
    }

    .buttons-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  button.pseudo-link-gray {
    color: $gray-4;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;

    &:hover, &:focus {
      color: $pill-gray;
      text-decoration: underline;
    }
  }
}
