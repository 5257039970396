
@import "stylesheets/scout/variables";

.fade-scroll-container {
  max-height: 170px;

  .fade-scrollable {
    max-height: 170px;
  }
}

.highlighted :deep(strong) {
  color: $k-blue;
}
