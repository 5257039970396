
hr {
  margin-top: 15px;
  margin-bottom: 15px;
}

.saved-search-item:last-of-type hr {
  display: none;
}

.edit {
  display: block;
}
