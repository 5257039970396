
@import "stylesheets/scout/variables";

.empty-list {
  border-radius: $border-radius-large;
  background-color: $k-lighter-gray;
  color: $pill-gray;
  padding: 25px;
  text-align: center;
}

.item-wrapper {
  border: 1px solid $medium-gray;
  border-radius: $border-radius-large;
  padding: 12px 15px;
}
