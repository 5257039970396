
@import "stylesheets/scout/variables";

.pagination {
  margin: 0;

  li {
    margin-right: 14px;

    &:last-child {
      margin-right: 0;
    }

    button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;

      &:hover {
        text-decoration: underline;
      }

      &:disabled {
        color: $k-gray;

        &:hover {
          cursor: default;
          text-decoration: none;
        }
      }

      &.current {
        color: $k-blue;
        text-decoration: underline;
      }
    }
  }
}

.scout-pagination .pagination {
  font-weight: 600;
}
