
@import "stylesheets/globals/variables";

.expand-btn, .expand-btn .base-icon {
  width: 20px;
  height: 20px;
}

.wired-box {
  .box-header {
    &.empty {
      border-bottom: none;
      border-radius: $border-radius-large;
    }
  }

  .box-content {
    max-height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;

    @media (min-width: 768px) {
      max-height: 360px;
    }
  }

  .with-scrollable {
    height: fit-content;

    &.scrolled-up::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 60px;
      border-bottom-right-radius: $border-radius-large;
      border-bottom-left-radius: $border-radius-large;
      background: linear-gradient(180deg, rgba($white, 0) 0%, $white 100%);
      pointer-events: none;
    }

    &.scrolled-down::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 60px;
      background: linear-gradient(180deg, $white 0%, rgba($white, 0) 100%);
      pointer-events: none;
      z-index: 1;
    }
  }
}
