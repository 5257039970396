
@import "stylesheets/globals/variables";

.request-item {
  background-color: $white;
  border: 1px solid $k-gray;
  column-gap: 5px;
  padding: 20px 30px;

  &.expanded {
    border-left: 3px solid $k-blue;
    padding-left: 28px;
  }

  &:first-child {
    border-top-left-radius: $border-radius-large;
    border-top-right-radius: $border-radius-large;
  }

  &:last-child {
    border-bottom-left-radius: $border-radius-large;
    border-bottom-right-radius: $border-radius-large;
  }

  &:not(:last-child) {
    border-bottom: none;
  }
}

.expand-btn, .expand-btn .base-icon {
  width: 20px;
  height: 20px;
}

.badges-container {
  @media (min-width: 768px) {
    padding-top: 15px;
    padding-bottom: 25px;
  }
}

.d-flex {
  &.wrap-only-xs {
    flex-wrap: wrap;

    @media (min-width: 768px) {
      flex-wrap: nowrap;

    }
  }
}

.rfp-badge {
  display: flex;
  min-height: 26px;
  border: 1px solid $medium-gray;
  border-radius: $border-radius-small;
  align-items: center;
  padding: 3px 6px;
  font-size: 12px;
  line-height: 14px;

  &.gray {
    border-color: $medium-gray;
    color: $pill-gray;
  }

  &.green {
    border-color: $k-green;
  }

  &.red {
    border-color: $k-red;
    color: $k-red;
  }

  &.blue {
    border-color: $high-match-score-blue;
    background-color: $high-match-score-blue;
    color: $white;
  }
}

.scout-rfp-badge {
  display: block;
  height: 22px;
  border: 1px solid $high-match-score-blue;
  border-radius: $border-radius-small;
  align-items: center;
  padding: 3px 6px;
  font-size: 13px;
  line-height: 14px;
  font-weight: 700;
  background-color: $high-match-score-blue;
  color: $white;
}

.status-dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;

  &.green {
    background: $k-green;
  }
}

.no-shrink {
  flex-shrink: 0;
}
