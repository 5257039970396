
@import "stylesheets/scout/variables";

.qr {
  @media (min-width: $screen-sm-min) {
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 0 15px;
  }

  @media (min-width: $screen-lg-min) {
    column-gap: 35px;
    padding: 0 35px;
  }
}

.secret {
  background: $k-light-gray;
  border-radius: $border-radius-base;
  padding: 5px;
  text-align: center;
  letter-spacing: 1px;
  font-size: 12px;
  word-break: break-all;
}

.nv-button-blue {
  width: unset;
  padding: 0 10px;

  @media (min-width: $screen-sm-min) {
    padding: 0 20px;
  }
}

.left-14 {
  margin-left: 14px;
}
