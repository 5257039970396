
@import "stylesheets/globals/variables";

.header-loading-button {
  margin-right: 29px;

  &.loading {
    margin-right: 0;
  }

  :deep(.spinner) {
    margin-right: 5px;
    margin-left: 5px;
    border-color: $k-gray;
    border-top-color: $k-darker-gray;
  }
}
