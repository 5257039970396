
.category-wrapper:not(:last-child) {
  margin-bottom: 15px;

  &.col-md-6 {
    margin-bottom: 30px;

    .category-name {
      margin-bottom: 5px;
    }
  }
}

.category-name {
  margin-bottom: 4px;
}
