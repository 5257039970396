
@import "stylesheets/globals/variables";

.missing-text {
  color: $pill-gray;
}

.a-form label {
  font-weight: 400;
}

:deep(.open) > .dropdown-menu > li > a {
  strong, b {
    color: $purple-5;
  }
}

.a-form {
  :deep(.check-option) {
    font-weight: 400;
    padding-left: 25px;
  }
}
