
@import "stylesheets/scout/variables";

.data-disclaimer {
  height: 203px;
  padding: 20px 25px;
  line-height: 1.3;
  background-color: $k-lighter-gray;

  .logo-container {
    max-width: 110px;
  }
}
