
@import "stylesheets/scout/variables";

.header {
  border-bottom: 1px solid $k-light-gray;
  font-weight: 700;
  margin-bottom: 5px;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-1 {
  flex: 1 1 0%;
}

.menu-item-container {
  padding: 5px;

  li {
    padding: 5px 10px;
  }
}

.invalid {
  border-color: $k-red;
}
