
@import "stylesheets/scout/variables";

td:not(.expanded) {
  max-height: 130px;
}

.question-text {
  height: 100%;
  overflow: hidden;
}

.expand-link, .collapse-link {
  height: 20px;
  font-size: 12px;
  text-transform: uppercase;

  .base-icon {
    fill: $k-blue;
    vertical-align: top;
  }
}

.collapse-link .base-icon {
  width: 16px;
  margin-left: 2px;
}
