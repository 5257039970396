
div {
  line-height: 1.4;
}

hr {
  margin: 10px 0;
}

.list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    margin-top: 5px;
    column-gap: 10px;
    display: flex;
    justify-content: space-between;
  }
}
