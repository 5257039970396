
@import "stylesheets/scout/variables";

.document-list-item {
  display: inline-block;
  position: relative;
  max-width: 200px;
  padding: 5px 30px 5px 15px;
  border: 1px solid $medium-gray;
  border-radius: 16px;

  .delete-button {
    position: absolute;
    top: 6px;
    right: 6px;
    padding: 5px;
    border: 0;
    background: transparent;

    &:hover {
      fill: $error-red;
    }

    .base-icon {
      display: block;
      width: 8px;
      height: 8px;
      fill: $k-dark-gray;

      &:hover, &:active {
        fill: $k-darker-gray;
      }

      :deep(svg) {
        display: block;
      }
    }
  }

  .file-name {
    line-height: 20px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
