
@import "stylesheets/scout/variables";

hr {
  margin-top: 5px;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-1 {
  flex: 1 1 0%;
}

.list {
  .list-item {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $k-light-gray;

    &:first-child {
      border-top: 1px solid $k-light-gray;
    }
  }
}

.organization-label {
  margin-left: 28px;
}
