
.flex-between {
  display: flex;
  justify-content: space-between;
}

.nv-button-blue.smaller {
  width: unset;
  padding: 0 20px;
}
