
@import "stylesheets/scout/variables";

.header {
  border-bottom: 1px solid $k-light-gray;
  font-weight: 700;
  margin-bottom: 5px;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.subheader {
  color: $pill-gray;
  font-size: 12px;
  margin-bottom: 15px;
}
