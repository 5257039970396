
@import "stylesheets/globals/variables";

.base-icon {
  &.big {
    width: 48px;
    height: 48px;
  }
}

.purple-1 {
  color: $purple-1;
}

.purple-2 {
  color: $purple-2;
}

.no-results {
  background-color: $white;
  border-radius: $border-radius-large;
  border: 1px solid $gray-7;
  padding: 25px 30px;
}
