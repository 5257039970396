
@import "stylesheets/scout/variables";

.upload-file-btn {
  min-width: 136px;
}

.selected-file {
  padding: 5px 15px;
  border-radius: 25px;
  border: 1px solid $medium-gray;
  max-width: 100%;
  width: fit-content;
}
