
@import "stylesheets/scout/variables";

.status-badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 8px;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 4px;

  &.full-response {
    background-color: $high-match-score-blue;
    color: $white;
    border: 1px solid $high-match-score-blue;
  }

  &.no-response {
    background-color: $white;
    color: $high-match-score-blue;
    border: 1px solid $high-match-score-blue;
  }

  &.closed {
    background-color: $k-purple;
    color: $white;
    border: 1px solid $k-purple;
  }

  &.draft {
    background-color: $mid-match-score-blue;
    color: $white;
    border: 1px solid $mid-match-score-blue;
  }

  &.firm-selected {
    background-color: $scout-green;
    color: $white;
    border: 1px solid $scout-green;
  }
}
