
@import "stylesheets/scout/variables";

.main-chart {
  @media (min-width: 768px) {
    padding-bottom: 50px;
  }
}

.count-container {
  top: 0;
  left: calc(50% - 70px);
  width: 140px;
  height: 140px;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    left: 0;
  }

  .count-sum {
    font-size: 26px;
    line-height: 30px;
  }

  .count-desc {
    color: $k-dark-gray;
    font-size: 11px;
  }
}

.gender-graph {
  :deep(.canvas-container) {
    @media (min-width: 992px) {
      padding-right: 15px;
    }
  }

  :deep(.chart-legend) {
    font-size: 10px;
  }

  :deep(.legend) {
    @media (min-width: 768px) {
      width: calc(100% - 160px);
    }

    @media (min-width: 992px) {
      width: calc(100% - 155px);
    }
  }
}

.rank-container {
  margin-top: 40px;

  @media (min-width: 992px) {
    margin-top: 0;
    border-left: 1px solid $k-light-gray;
  }

  .rank-label {
    height: 40px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.4;
  }

  .rank-content {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.gray-award {
  background-image: url("../../../../images/gray-award.svg");
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100%;
}

.counter-container {
  @media (min-width: 768px) {
    &:not(:first-child) {
      border-left: 1px solid $k-light-gray;
    }
  }
}
