
@import "stylesheets/scout/lists";

.award-list {
  .lists-to-csv {
    overflow: hidden;
    width: 100%;
    white-space: normal;

    :deep {
      & > ol:first-child, & > ol:first-child ~ ol {
        padding: 0;

        & > li > p { display: block; }

        & > li > ol {
          @extend %flattened-list;
          @extend %decimal-list;

          & > li > ol {
            @extend %parenthesized-list;
            @extend %roman-numeral-list;

            margin-left: 3px;
          }
        }
      }

      & > ul:first-child, & > ul:first-child ~ ul {
        @extend %headed-list;

        & > li > ul {
          @extend %flattened-list;
        }
      }

      & > p ~ ol, & > div ~ ol {
        @extend %flattened-list;
        @extend %decimal-list;

        & > li > ol {
          @extend %parenthesized-list;
          @extend %roman-numeral-list;

          margin-left: 3px;
        }
      }

      & > p ~ ul, & > div ~ ul {
        @extend %flattened-list;

        & > li > ul {
          @extend %parenthesized-list;

          margin-left: 3px;
        }
      }
    }
  }
}
