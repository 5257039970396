
@import "stylesheets/globals/variables";

.messaging-button {
  position: fixed;
  height: 60px;
  right: 20px;
  bottom: 40px;
  z-index: 2;

  button {
    height: 60px;
    padding: 20px 30px;
    border: 0;
    border-radius: 30px;
    background-color: $k-darker-gray;
    color: $white;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;

    .base-icon {
      fill: $white;
    }
  }

  .unread-alert {
    position: absolute;
    top: -5px;
    right: -5px;
    min-width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 30px;
    background-color: $k-red;
    color: $white;
    text-align: center;
  }
}
