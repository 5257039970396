
@import "stylesheets/scout/variables";

.note-icon {
  position: absolute;
  top: 17px;
  left: 13px;
}

.interface-note-box {
  padding: 15px 15px 15px 40px;
  border-left: 7px solid $k-blue;

  &.selected {
    .note-icon {
      fill: $scout-green;
    }
  }

  &.declined {
    .note-icon {
      fill: $pill-gray;
    }
  }
}
