
@import "stylesheets/scout/variables";

.wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (width >= $screen-sm-min) {
    padding: 8%;
  }
}

.icon {
  display: flex;
  background: $newblue-10;
  border-radius: 100px;
}

.icon-lg {
  width: 64px;
  height: 64px;
}

:deep(.uiv) {
  display: inline;
}
