
@import "stylesheets/scout/variables";

.empty-state {
  padding: 20px;
  background: $gray-9;
  border-radius: $border-radius-large;
  line-height: 1.4;

  svg {
    fill: $gray-4;
  }
}
