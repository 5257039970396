
.flex-between {
  display: flex;
  justify-content: space-between;
}

.nv-button-blue.a-button-size {
  width: unset;
  padding: 0 24px;
}

.icon-container {
  text-align: center;

  svg {
    width: 100px;
  }
}
