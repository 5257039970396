
@use "stylesheets/globals/colors";

.email-input-container {
  position: relative;

  .over-input {
    position: absolute;
    top: 50%;
    right: 16px;
    font-style: italic;
    transform: translateY(-50%);
    color: colors.$purple-5;
  }
}
