
@import "stylesheets/scout/variables";

.base-icon.delete-btn {
  width: 12px;
  height: 12px;
  margin-bottom: 3px;
  fill: $k-blue;
}

:deep(.faded-section.fade-down)::after {
  background: linear-gradient(180deg, rgba($white, 0) 0%, $k-lighter-gray 100%);
}
