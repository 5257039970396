
.confirmation-modal {
  padding: 20px;

  @media (min-width: 768px) {
    padding: 30px;
  }

  .confirmation-header {
    margin-bottom: 20px;
    border-radius: 6px 6px 0 0;
    font-size: 20px;
    line-height: 1.3;
  }

  .confirmation-message {
    margin-bottom: 40px;
    font-size: 14px;
  }
}
