
.summary {
  width: 100%;
  position: relative;
  text-align: center;

  @media (min-width: 768px) {
    .canvas-container, .legend {
      float: left;
    }

    .canvas-container {
      padding-right: 20px;
    }
  }

  @media (min-width: 992px) {
    .canvas-container {
      padding-right: 30px;
    }
  }

  .canvas-container .doughnut-chart {
    position: relative;
  }
}

.legend {
  @media (min-width: 768px) {
    width: calc(100% - 220px);
  }

  @media (min-width: 992px) {
    width: calc(100% - 230px);
  }
}
