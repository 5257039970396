
@import "stylesheets/globals/variables";

.shadowed-box {
  min-height: unset;
}

.icon-wrapper {
  position: relative;
  width: 22px;

  .base-icon {
    width: 20px;
    height: 20px;
    fill: $k-dark-gray;
  }

  &::after {
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    top: -1px;
    right: -3px;
    background-color: $red-4;
    border-radius: 50%;
  }
}

.notification-box {
  padding: 10px 20px 10px 13px;

  &.info {
    border-left: 7px solid $k-blue;
  }
}
