
@import "stylesheets/scout/variables";

.grid-container {
  position: relative;
  padding-bottom: 30px;

  .category-wrapper {
    position: relative;
    z-index: 1;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  .background-grid {
    position: absolute;
    top: 0;
    left: calc(25% + 2.5px);
    width: calc(75% - 2.5px);
    height: calc(100% - 28px);
    background-image:
      repeating-linear-gradient(transparent 0 1px, transparent 1px 100%),
      repeating-linear-gradient(90deg, $k-light-gray 0 1px, transparent 1px 100%);
    background-size: 20% 100%;
    border-right: 1px solid $k-light-gray;
    z-index: 0;
  }

  .score-label {
    font-size: 12px;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  .x-axis-row {
    position: absolute;
    bottom: 0;
    left: calc(25% + 7.5px);
    width: calc(75% - 2.5px);
    height: 20px;
    text-align: right;
    font-size: 11px;
    line-height: 1.3;

    .x-axis-col {
      float: left;
      width: calc(100% / 5);
    }

    .x-axis-item {
      display: inline-block;
      width: 10px;
      cursor: pointer;
      text-align: center;
    }
  }
}

.legend-panel {
  padding: 10px 15px;
  border: 1px solid $k-gray;
  border-radius: $border-radius-base;
  font-size: 11px;
  line-height: 12px;

  .rating-bar {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 7px;
    border-radius: 7px;
    background: $k-blue;
    background: linear-gradient(45deg, $k-blue, $k-purple);

    &.light-to-dark-blue-background {
      background: $k-blue;
      background: linear-gradient(45deg, $k-blue, $night-blue);
    }

    .dot {
      position: absolute;
      top: 50%;
      right: 0;
      width: 14px;
      height: 14px;
      transform: translate(50%, -50%);
      background: $k-blue;
      border: 2px solid $white;
      border-radius: 50%;
    }

    &.blue-purple-background .dot {
      background: $k-blue;
    }

    &.light-to-dark-blue-background .dot {
      background: $k-purple;
    }
  }

  .diamond-container {
    display: inline-block;

    @media(max-width: 767px) {
      width: 24px;
    }
  }

  .diamond {
    display: inline-block;
    width: 8px;
    height: 8px;
    transform: rotate(-45deg);
    background-color: $pill-gray;
    border-radius: 1px;
  }

  .range-bar {
    display: inline-block;
    width: 24px;
    height: 12px;
    border: 6px solid $grey-3;
    border-radius: $border-radius-small;
  }
}
