
@import "stylesheets/scout/variables";

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}

.list-content-wrapper {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.list-item-with-col-borders:last-child {
  border-bottom: 0;
}

.totals-row {
  border-top: 2px solid $k-dark-gray;
}

.responsive-text-align {
  text-align: left;

  @media (min-width: $screen-sm-min) {
    text-align: center;
  }
}
