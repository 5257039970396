
@import "stylesheets/globals/variables";

.profile-header {
  display: flex;
  flex: 1;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 30px;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 0;
  }

  &.top-aligned {
    align-items: flex-start;
  }

  &.narrow {
    @media (min-width: $screen-sm-min) {
      min-width: 55%;
      max-width: 55%;
    }

    @media (min-width: $screen-md-min) {
      min-width: 60%;
      max-width: 60%;
    }
  }

  .main-text {
    font-size: 18px;
    font-weight: 600;
    text-align: left;

    @media (min-width: $screen-sm-min) {
      font-size: 20px;
    }
  }

  .secondary-text {
    text-align: left;
  }
}

.profile-header :deep(.user-pic).larger-pic {
  @media (max-width: $screen-xs-max) {
    width: 60px;
    height: 60px;
  }
}
