
hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

label {
  line-height: 1.3;
}

input[type="number"] {
  padding-right: 11px;
  padding-left: 11px;
}

.input {
  width: 30%;
}

.ethnicity-column {
  padding-right: 5px;
  padding-left: 5px;
}
