
@use "stylesheets/globals/colors";

.a-form {
  .check-option input[type="checkbox"] {
    top: 1px;

    ~ .check {
      top: 1px;
      width: 16px;
      height: 16px;
      border: 1px solid colors.$gray-3;

      &:hover {
        border-color: colors.$purple-5;
      }
    }
  }

  .check-option input:checked ~ .check {
    border-color: colors.$purple-5;
    background-color: colors.$purple-5;
  }

  .check-option input[type="checkbox"]:checked ~ .check {
    svg {
      position: relative;
      top: -4px;
      left: -2px;
      fill: white;
    }
  }

  .check-option input[type="checkbox"]:not(:checked) ~ .check {
    svg {
      display: none;
    }
  }
}
