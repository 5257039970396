
@import "stylesheets/scout/variables";

.note-icon {
  position: absolute;
  top: 19px;
  left: 15px;
}

.interface-note-box {
  padding: 15px 15px 15px 40px;
  border-left: 7px solid $k-blue;

  &.warning {
    background: $p-yellow-bg;
    border-left: 7px solid $p-yellow;

    .note-icon {
      fill: $p-yellow;
    }
  }
}
