
.doughnut-chart {
  position: relative;
}

.doughnut-container {
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: 0;
  }

  canvas {
    position: absolute;
  }
}
