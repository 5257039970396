
 @import "stylesheets/scout/variables";

 .label-container {
   height: 233px;
 }

 .bar-container {
   height: 233px;
   width: 56px;
 }

 .footer-container {
   text-align: center;
   padding: 10px 15px;
   border: 1px solid $bg-color;
   border-radius: $border-radius-base;
   background: $bg-color;

   svg {
     padding-bottom: 2px;
   }
 }

 .t-bar-overages {
   height: v-bind(overagesRenderStr);;
   width: 10px;
 }

 .t-bar {
   height: v-bind(tBarRenderStr);
   width: 10px;
   border-top: 2px solid $k-dark-gray;
   border-bottom: 2px solid $k-dark-gray;
   background: linear-gradient($k-dark-gray, $k-dark-gray) no-repeat center/2px 100%;
 }

 .gold-bar {
   border-radius: 4px 4px 0 0;
   background-color: $yellow-7;
   width: 56px;
   height: v-bind(overagesRenderStr);
 }

 .gray-bar {
   background-color: $k-gray;
   width: 56px;
   height: v-bind(remainingSpendRenderStr);

   &.stacked {
     border-radius: 4px 4px 0 0;
   }

   &.unstacked {
     border-radius: $border-radius-base;
   }
 }

.blue-bar {
  border-radius: 0 0 4px 4px;
  margin-top: 2px;
  background-color: $blue-6;
  width: 56px;
  height: calc(v-bind(totalSpendRenderStr) - 2px);
}

 .remaining-bar {
   width: 56px;
   height: 200px;
 }

 .warning-icon {
   fill: $yellow-7;
 }
