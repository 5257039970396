
@import "stylesheets/scout/variables";

.remove-btn {
  align-items: center;
  color: $k-darker-gray;
  display: flex;
  justify-content: right;
}

.clerkship {
  .no-left-gutter {
    padding-left: 0;
  }

  .remove-clerkship-btn {
    position: relative;
    top: 37px;

    @media (min-width: 768px) {
      top: 35px;
    }
  }
}

ul.small-bullet > li {
  text-indent: -10px;
}
