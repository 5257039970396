
label.nv-button-white {
  align-content: center;
}

:deep(.cropper-modal) {
  background-color: transparent;
  display: none;
}

:deep(.cropper-container) {
  overflow: hidden;
}
