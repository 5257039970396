
.menu-item-container {
  padding: 20px;

  label {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.fit-content {
  width: fit-content;
}
