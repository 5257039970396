
.bar-chart-wrapper {
  position: relative;
}

ul {
  z-index: 50;
  position: relative;

  li {
    margin: 15px 0;
    font-size: 12px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .item-name {
      width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: top;
      line-height: 20px;
    }

    .item-value {
      .bar {
        height: 16px;
        display: inline-block;
        margin-right: 5px;
        border-radius: 4px;
      }

      .total {
        font-size: 11px;
        font-weight: 600;
        vertical-align: top;
        line-height: 20px;
      }
    }
  }
}

@media(min-width: 768px) {
  ul li {
    margin: 8px 0;

    .item-name, .item-value {
      display: inline-block;
    }

    .item-name {
      width: 212px;
      text-align: right;
    }

    .item-value {
      margin-left: 10px;
    }
  }
}

@media(min-width: 992px) {
  ul li .item-name {
    width: 180px;
  }
}

@media(min-width: 1024px) {
  ul li .item-name {
    width: 205px;
  }
}

@media(min-width: 1200px) {
  ul li .item-name {
    width: 300px;
  }
}

.percentage-panel {
  top: 0;
  left: 0;
  z-index: 30;
  position: absolute;

  @media(min-width: 768px) {
    left: 206px;
  }

  @media(min-width: 1024px) {
    left: 236px;
  }

  @media(min-width: 1200px) {
    left: 306px;
  }
}

.item-icon .base-icon {
  vertical-align: text-bottom;
}
