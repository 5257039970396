
hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.menu-item-container {
  padding: 5px;

  li {
    padding: 5px 10px;
  }
}
