
@import "stylesheets/scout/variables";

.base-icon.delete-btn {
  width: 12px;
  height: 12px;
  margin-bottom: 3px;
  fill: $k-blue;
}

.vertical-center-lg {
  @media (min-width: $screen-lg-min) {
    display: flex;
    align-items: center;
    min-height: 100%;
  }
}

.edit-buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;

  @media (min-width: $screen-md-min) {
    justify-content: flex-end;
    margin-top: 0;
  }
}

