
@import "stylesheets/scout/variables";

.draggable-saved-search {
  margin-bottom: 10px;

  &:hover {
    cursor: grab;
  }

  &.dragging {
    opacity: .4;
  }

  &.dragover {
    margin-bottom: 4px;
    padding-bottom: 4px;
    border-bottom: 2px solid $k-blue;
  }

  &.dragging.dragover {
    margin-bottom: 10px;
    padding-bottom: 0;
    border-bottom: 0;
  }

  &:last-child {
    margin-bottom: 30px;

    &.dragover {
      margin-bottom: 24px;
      padding-bottom: 4px;
    }

    &.dragging.dragover {
      margin-bottom: 30px;
      padding-bottom: 0;
    }
  }

  .base-icon {
    fill: $k-gray;
  }
}

.droppable-saved-search {
  height: 20px;
  min-height: 20px;

  &.dragover {
    margin-top: -5px;
    margin-bottom: 5px;
    border-bottom: 2px solid $k-blue;
   }
}
