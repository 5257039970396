
.keyword-search-container {
  width: 100%;

  &.searched {
    width: calc(100% - 50px);
  }
}

.clear-button {
  width: 50px;
}
