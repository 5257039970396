
@import "stylesheets/scout/variables";

.step-header {
  margin-bottom: 30px;

  @media (min-width: $screen-sm-min) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.step-item {
  display: none;
  padding-right: 5px;

  @media (min-width: $screen-sm-min) {
    display: inline-block;
    padding-left: 5px;
    padding-bottom: 15px;
  }

  .stepper-label-text {
    font-size: 15px;
    font-weight: 600;
  }

  &.selected {
    display: inline-block;
    border-bottom: 4px solid $k-blue;
    padding-bottom: 11px;
  }
}

.add-spacer-after {
  position: relative;

  &::after {
    @media (min-width: $screen-sm-min) {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(100% + 15px);
      height: 1px;
      background: $k-gray;
      // this is the calculation for the space between objects that have
      // display:flex and justify-content: space-between.
      width: calc(((var(--step-header-width) - var(--step-items-sum)) / var(--number-of-steps)));
    }
  }
}

.stepper-label {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  color: $white;
  border-radius: 50%;
  font-weight: 700;
  text-align: center;
  line-height: 24px;

  &.status-active {
    display: inline-block;
    background: $k-blue;
  }

  &.status-incomplete {
    background: $pill-gray;
  }

  &.status-complete {
    background: $k-blue;

    .base-icon {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 16px;
      height: 16px;
      fill: $white;
    }
  }
}
