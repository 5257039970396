
div {
  line-height: 1.4;
}

.panel-item {
  column-gap: 10px;
  display: flex;
  justify-content: space-between;
}
