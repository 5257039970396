
@import "stylesheets/scout/variables";

.rating-bar-container {
  position: relative;

  .rating-bar, .rating-fill {
    position: relative;
    z-index: 1;

    .dot {
      position: absolute;
      top: 50%;
      right: 0;
      width: 18px;
      height: 18px;
      transform: translate(50%, -50%);
      background: $k-blue;
      border: 3px solid $white;
      border-radius: 50%;
    }

    &.blue-purple-background .dot {
      background: $k-blue;
    }

    &.light-to-dark-blue-background .dot {
      background: $k-purple;
    }

    .no-data-text {
      position: absolute;
      top: -6px;
      width: 100%;
      text-align: center;
      font-size: 11px;
      font-style: italic;
      color: $pill-gray;
    }
  }

  .range-bar {
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    height: 23px;
    background-color: $grey-3;
    border-radius: $border-radius-small;
    z-index: 0;
  }

  .diamond {
    position: absolute;
    top: -10px;
    width: 8px;
    height: 8px;
    background-color: $pill-gray;
    transform: rotate(45deg);
    border-radius: 1px;
  }

  .diamond-tooltip-overlay {
    position: absolute;
    top: -10px;
    width: 8px;
    height: 8px;
    background-color: transparent;
    transform: rotate(45deg);
    border-radius: 1px;
    z-index: 2;
  }
}
