
@import "stylesheets/scout/variables";

.border-right-medium-gray {
  @media (min-width: $screen-sm-min) {
    border-right: 1px solid $medium-gray;
  }
}

:deep(.doughnut-container) {
  margin: 0 auto;
}

.quote-count {
  position: absolute;
  top: 56px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1.2;
}
