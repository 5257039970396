
@import "stylesheets/globals/variables";

.status-icon {
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  text-align: center;

  .spinner {
    display: inline-block;
    width: 80px;
    height: 80px;
    animation: spinner 1.3s linear infinite;
    border: 8px solid $purple-6;
    border-radius: 50%;
    border-top-color: $white;
  }

  &.success {
    border: 8px solid $green-5;

    .base-icon {
      width: 30px;
      height: 30px;
      margin-top: 17px;
      fill: $green-5;
    }
  }

  &.failure {
    border: 8px solid $red-4;

    .base-icon {
      width: 26px;
      height: 26px;
      margin-top: 19px;
      fill: $red-4;
    }
  }
}
