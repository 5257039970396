
.add-input-container {
  position: relative;
}

.add-input {
  padding-right: 40px;
}

.add-icon {
  position: absolute;
  top: 15px;
  right: 15px;

  @media (min-width: 768px) {
    top: 13px;
  }
}

.add-icon, .base-icon {
  display: block;
  width: 15px;
  height: 15px;
}
