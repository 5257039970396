
@import "stylesheets/scout/variables";

.dropdown-text {
  border-bottom: 1px solid $k-light-gray;
  padding: 15px 0;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.4;
}

.content {
  max-width: 90%;
  padding-top: 10px;
}
