
@import "stylesheets/scout/variables";

.yellow-message-box .message {
  font-weight: normal;
}

.draggable-scout-tag {
  margin-bottom: 20px;

  &:hover {
    cursor: grab;
  }

  &.dragging {
    opacity: .4;
  }

  &.dragover {
    margin-bottom: 9px;
    padding-bottom: 9px;
    border-bottom: 2px solid $k-blue;
  }

  &.dragging.dragover {
    margin-bottom: 20px;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.droppable-scout-tag {
  height: 20px;
  min-height: 20px;

  &.dragover {
    margin-top: -10px;
    margin-bottom: 10px;
    border-bottom: 2px solid $k-blue;
  }
}
