
@import "stylesheets/globals/variables";

.search-highlights {
  margin-left: 15px;

  @media (min-width: 768px) {
    margin-left: 40px;
  }
}

hr.search-separator {
  margin-top: 7px;
  margin-bottom: 7px;
}

.info-icon {
  width: 13px;
  margin-bottom: 2px;
  fill: $k-dark-gray;
}
