
.separator {
  margin: 0 -30px;
}

.notice-text {
  margin-left: -30px;
  margin-right: -30px;
  margin-top: -30px;
}
