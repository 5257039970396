
@import "stylesheets/scout/variables";

.missing-photo {
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  background-color: $k-light-gray;
  box-shadow: 0 0 8px 0 rgba($black, .1);
  font-size: 17px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  cursor: default;
  user-select: none;
  vertical-align: middle;
}

.name-text {
  font-size: 17px;
}

.name-plate.compact {
  min-height: auto;

  .name-text {
    font-size: 13px;
    font-weight: 600;
  }
}
