
.devices-image {
  width: 90px;
  height: 68px;
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.instructions {
  @media (min-width: 768px) {
    display: flex;
    column-gap: 50px;
    justify-content: space-between;
    padding-right: 14px;
    align-items: center;
  }
}
