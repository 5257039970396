
@import "stylesheets/scout/variables";

.percentage-input-wrapper {
  position: relative;

  input[type="number"] {
    padding-right: 25px;

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      display: none;
    }
  }

  &::after {
    content: "%";
    position: absolute;
    top: 13px;
    right: 10px;
    color: $pill-gray;
    pointer-events: none;

    @media (min-width: 768px) {
      top: 11px
    }
  }
}
