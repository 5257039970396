
@import "stylesheets/globals/variables";

.text-button {
  background: none;
  border: none;
  padding-right: 0;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    border-color: $k-blue;
    color: $k-blue;
    cursor: pointer;
  }

  &.disabled {
    color: $k-dark-gray;
    cursor: default;
    text-decoration: none;
  }
}
