
@import "stylesheets/globals/variables";

.review-list {
  padding-inline-start: 10px;
  margin-bottom: 0;

  li {
    font-size: 13px;
    list-style-type: none;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  li:before {
    content: "•";
    font-size: 80%;
    padding-right: 10px;
  }
}

.checkmark {
  width: 16px;
  height: 16px;
}

.purple-link {
  color: $purple-5;

  &:hover, &:focus {
    color: $purple-5;
  }
}

