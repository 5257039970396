
@import "stylesheets/globals/variables";

.search-filter-value {
  color: $k-blue;
  font-size: 12px;

  &::first-letter {
    text-transform: capitalize;
  }
}
