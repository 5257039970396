
@import "stylesheets/scout/variables";

.rating-bar-container {
  position: relative;

  .rating-bar{
    position: relative;
    z-index: 1;

    .dot {
      position: absolute;
      top: 50%;
      right: 0;
      width: 8px;
      height: 8px;
      margin-left: 0.5px;
      transform: translate(-50%, -50%);
      background: $high-match-score-blue;
      border-radius: 50%;

      &.from-marketplace {
        background: $k-purple;
      }
    }

    .no-data-text {
      position: absolute;
      top: -6px;
      width: 100%;
      text-align: center;
      font-size: 11px;
      font-style: italic;
      color: $pill-gray;
    }
  }
}
