
@import "stylesheets/globals/variables";

.opportunity-listing {
  background-color: $white;
  border-radius: $border-radius-large;
  border: 1px solid $gray-7;
  padding: 25px 30px;
}

.base-icon {
  &.green {
    fill: $green-5;
  }

  &.yellow {
    fill: $p-yellow;
  }
}

.left-section {
  padding-bottom: 20px;
  border-bottom: 1px solid $gray-7;

  @media (min-width: $screen-sm-min) {
    padding-bottom: 0;
    border-right: 1px solid $gray-7;
    border-bottom: none;
    min-height: v-bind(minLeftSectionHeight);
  }
}

.right-section {
  padding-top: 20px;

  @media (min-width: $screen-sm-min) {
    padding-top: 0;
  }
}

.opportunity-id {
  margin-bottom: 10px;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 25px;
  }
}

.practice-areas {
  position: relative;
  max-height: 53px;
  overflow: hidden;

  @media (min-width: $screen-sm-min) {
    position: absolute;
    bottom: 0;
  }
}

.color-indicator {
  width: 9px;
  height: 9px;
}

:deep(.color-tag) {
  white-space: nowrap;
  padding: 3px 6px;
  font-size: 13px;
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
