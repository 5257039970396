
label {
  margin-bottom: 0;
  font-weight: 400;
}

hr {
  margin-top: 5px;
  margin-bottom: 5px;
}
