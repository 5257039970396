
@import "stylesheets/scout/variables";

.legend-text {
  color: $pill-gray;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  text-transform: uppercase;
}

.diamond {
  width: 8px;
  height: 8px;
  transform: rotate(-45deg);
  background-color: $pill-gray;
  border-radius: 1px;
}

.range-bar {
  width: 24px;
  height: 12px;
  border: 6px solid $grey-3;
  border-bottom: 0;
  border-radius: 12px 12px 0 0;
}
