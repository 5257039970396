
@import "stylesheets/scout/variables";

.rating-fill {
  position: relative;
}

.dot {
  background: $k-darker-gray;
  border: 2px solid $white;
  border-radius: 50%;
  height: 12px;
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translate(0, -50%);
  width: 12px;

  &.purple-dot {
    background: $k-purple;
  }

  &.blue-dot {
    background: $k-blue;
  }
}
