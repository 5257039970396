
@import "stylesheets/scout/variables";

.rfq-firms-wrapper {
  padding: 20px;
  border: 1px solid $medium-gray;
  border-radius: $border-radius-large;
  border-left: 7px solid $k-blue;

  @media (min-width: $screen-sm-min) {
    padding: 20px 30px;
  }
}

.rfq-quote-recipients-wrapper {
  padding: 15px;
  background-color: $k-lighter-gray;
  border-radius: $border-radius-large;

  @media (min-width: $screen-sm-min) {
    padding: 20px 30px;
  }

  @media (min-width: $screen-lg-min) {
    padding: 30px 40px;
  }
}

.sent-to-marketplace-wrapper {
  padding: 20px 30px;
  border: 1px solid $medium-gray;
  border-radius: $border-radius-large;
  border-left: 7px solid $purple-5;

  &.with-scout-firms {
    margin-top: 20px;
  }
}

.rfq-recipient-item {
  border-top: 1px solid $medium-gray;
  padding-top: 20px;
  padding-bottom: 20px;

  &:last-child {
    padding-bottom: 0;
  }

  .base-icon {
    fill: $k-dark-gray;

    &:hover {
      fill: $k-darker-gray;
    }
  }
}

:deep(.mail-icon) {
  width: 13px;
  height: 13px;
}
