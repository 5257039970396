
@import "stylesheets/scout/variables";

.lawyer-total-matters {
  max-width: 300px;
}

.chart-dollar-container {
  display: flex;
  align-items: center;
  min-height: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.dollar-num {
  font-size: 22px;
  line-height: 1.3;
}

.dollar-text {
  color: $k-dark-gray;
  font-size: 11px;
  line-height: 1.3;
}

.chart-container {
  width: 100%;
  margin-bottom: 20px;
}

.dollars-container {
  width: 100%;
}

@media (min-width: 768px) {
  .chart-container {
    width: 52%;
    margin-bottom: 0;
  }

  .dollars-container {
    width: 48%;
  }
}

@media (min-width: 992px) {
  .chart-container, .dollars-container {
    width: 50%;
  }

  .dollar-num {
    font-size: 36px;
  }

  .dollar-text {
    font-size: 13px;
  }
}
