
@import "stylesheets/scout/variables";

#close-icon {
  vertical-align: baseline;
}

#rfp-permission-tooltip {
  fill: $gray-3;
  margin-left: 3px;
  margin-top: 2px;
}
