
.missing-photo {
  font-weight: 600;
  width: 30px;
  height: 30px;
  padding: 8px 0;

  @media (min-width: 768px) {
    width: 40px;
    height: 40px;
    padding: 12px 0;
  }
}
