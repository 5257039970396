
@use "stylesheets/globals/colors";

:deep(.a-form) {
  .form-control {
    border-color: colors.$gray-6;

    &::placeholder {
      color: colors.$gray-3;
    }
  }

  label {
    font-weight: 700;
  }

  .check-option {
    padding-right: 30px;
  }

  .check-option input:checked ~ .check {
    border-color: colors.$purple-5;
    background-color: colors.$purple-5;
  }
}

:deep(i.counter) {
  font-size: 13px;
}

:deep(.k-dropdown) {
  .dropdown-toggle {
    border-color: colors.$gray-6;

    &.placeholder {
      color: colors.$gray-3;
    }
  }
}
