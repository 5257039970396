
@import "stylesheets/globals/variables";

.work-history-fields {
  padding: 20px;
  background-color: $gray-10;
  border: 1px solid $gray-7;
  border-radius: $border-radius-large;

  @media (min-width: $screen-sm-min) {
    padding: 30px;
  }
}
