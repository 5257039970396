
.flex {
  display: flex;
}

.width-95 {
  width: 95%;
}

.margin-top-5 {
  margin-top: 5px;
}

.remove-icon-spacing {
  margin-top: 40px;
  margin-right: 10px;

  @media (min-width: 768px) {
    margin-top: 38px;
  }
}
