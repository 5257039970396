
.container {
  display: flex;
  align-items: center;
  height: 100vh;

  .priori-brand-icon {
    width: 140px;
    height: 26px;
  }
}
