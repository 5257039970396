
.list-item {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  padding: 10px 0;
  line-height: 1.4;

  @media (min-width: 768px) {
    column-gap: 100px;
  }
}
