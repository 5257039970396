
@import "stylesheets/globals/variables";

.file-selector {
  width: 100%;
}

.secondary-btn-blue.file-selector-text {
  padding-top: 10px;
}

.styled-date-picker {
  position: absolute;
  z-index: 1000;
}

.date-picker-error {
  border-color: $k-red !important;
}
