
@import "stylesheets/scout/variables";

.slider-container {
  padding: 0 10px;
}

.k-form .has-error, .a-form .has-error {
  color: $k-darker-gray;
}

:deep(.vue-slider-dot-tooltip-top) {
  top: -6px;
}
