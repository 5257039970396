
.checkbox-container {
  margin: 0 auto;
  max-width: 425px;
}

.button-container {
  margin: 30px auto 0;
  width: 150px;
}
