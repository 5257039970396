
@import "stylesheets/globals/variables";

.school-fields {
  padding: 20px;
  background-color: $gray-10;
  border: 1px solid $gray-7;
  border-radius: $border-radius-large;

  @media (min-width: $screen-sm-min) {
    padding: 30px;
  }
}

.start-year {
  :deep(.k-dropdown)::after {
    @media (min-width: $screen-sm-min) {
      content: '–';
      position: absolute;
      top: 9px;
      right: -14px;
    }
  }
}
