
@import "stylesheets/globals/variables";

.l-st1 {
  fill: $k-darker-gray;
}

.l-st4 {
  fill: $light-gray-bg;
}
