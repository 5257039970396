
@import "stylesheets/scout/variables";

.space-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.rfq-not-authorized {
  background-color: $k-lighter-gray;
  color: $pill-gray;
}

.item-wrapper {
  border-radius: $border-radius-large;
  padding: 12px 15px;
}
