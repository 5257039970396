
@import "stylesheets/scout/variables";

hr {
  margin: 10px 0;
}

.nv-button-blue, .nv-button-white {
  width: unset;

  &.padded {
    padding: 0;

    @media (min-width: $screen-sm-min) {
      padding: 0 30px;
    }
  };

  &.extra-padded {
    padding: 0 60px;
  };

  &.dark {
    background-color: $alt-dark-blue;
  }
}

.asymmetrical {
  @media (min-width: $screen-sm-min) {
    padding: 30px 50px;
  }
}
