
@import "stylesheets/scout/variables";

.data-disclaimer {
  line-height: 1.3;

  @media(min-width: 768px) {
    padding: 50px 115px;
  }

  .feature-lab-logo {
    fill: $newblue-5;
  }

  .feature-lab-logo-container {
    max-width: 200px;
  }

  .logo-container {
    max-width: 120px;
  }
}
