
@import "stylesheets/scout/variables";

.questions-wrapper {
  padding: 20px;
  background-color: $k-lighter-gray;
  border-radius: $border-radius-large;

  @media (min-width: $screen-sm-min) {
    padding: 30px;
  }
}
