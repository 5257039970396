
.k-dropdown {
  .dropdown-toggle {
    @media (min-width: 768px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .dropdown-menu {
    .menu-item-container {
      padding: 8px 0;
    }

    .menu-item {
      &:hover {
        background-color: transparent;
        cursor: default;
      }

      &.empty:hover {
        cursor: pointer;
      }

      .dropdown-row-inner {
        border-bottom: 0;
        padding: 8px 0;
      }
    }
  }
}
