
@import "stylesheets/scout/variables";

.box {
  display: inline-block;
  border: 1px solid $k-gray;
  border-radius: $border-radius-base;
  padding: 5px;
}

.sub-text {
  color: $pill-gray;
}

.divider {
  padding-left: 1px;
  border-left: 1px solid $k-gray;
}
