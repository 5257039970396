
@import "stylesheets/scout/variables";

.clear-panel {
  padding: 0;
  border: 0;
  line-height: 1.4;

  @media (min-width: $screen-sm-min) {
    padding: 30px 25px 30px 20px;
    border: 1px solid $k-light-gray;
    border-radius: $border-radius-large;
  }
}

.first-column {
  padding-bottom: 20px;
  border-bottom: 1px solid $k-light-gray;

  @media (min-width: $screen-sm-min) {
    padding-right: 20px;
    padding-bottom: 0;
    border-right: 1px solid $k-light-gray;
    border-bottom: 0;
  }
}
