
@import "stylesheets/globals/variables";

.content {
  max-height: v-bind(maxContentHeight);
  line-height: 1.4;
  overflow: hidden;
  position: relative;
}

button {
  position: absolute;
  bottom: 0;
}
