
@import "stylesheets/scout/variables";

.error-text {
  color: $error-red;
  font-size: 11px;
  font-weight: 600;
}

.button-container {
  margin: 0 auto;
  width: 150px;
}
