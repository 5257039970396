
@import "stylesheets/scout/variables";

.question-wrapper {
  padding: 10px 15px;
  border: 1px solid $k-gray;
  border-radius: $border-radius-large;
}

.sub-text {
  color: $pill-gray;
  font-size: 12px;
  border-bottom: 1px dotted $pill-gray;
}
