
@import "stylesheets/scout/variables";

ul {
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;

  a {
    color: $k-darker-gray;
    text-decoration: none;
    background-color: $white;

    width: 100%;
    height: 100%;
    display: inline-block;
    padding: 10px 30px;
    border: 1px solid $k-light-gray;
    border-radius: $border-radius-base;

    &:hover {
      text-decoration: none;
      background-color: $blue-hover;
      border-color: $k-blue;
    }
  }
}
