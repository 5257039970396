
@import "stylesheets/scout/variables";

.empty-list {
  border-radius: $border-radius-large;
  background-color: $k-lighter-gray;
  color: $pill-gray;
}

.blue-accent {
  background: $blue-10;
  padding: 10px 20px 20px 20px;
  border-radius: $border-radius-large;
}
