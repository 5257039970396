
.secondary-btn-blue, .nv-button-blue {
  width: unset;
  padding: 0 10px;

  @media (min-width: 768px) {
    padding: 0 20px;
  }
}

.left-14 {
  @media (min-width: 768px) {
    margin-left: 14px;
  }
}
