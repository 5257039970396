
@import "stylesheets/globals/variables";

.proposal-navigation-container {
  @media (min-width: $screen-sm-min) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
}

.nv-button-clear {
  &:hover, &:active {
    background-color: rgba($white, .2);
  }
}
