
.nv-button-blue.a-button-size {
  width: unset;
  padding: 0 33px;
}

.settings-screen {
  width: 100%;
  height: 100%;
}
