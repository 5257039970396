
@import "stylesheets/globals/variables";

.k-dropdown {
  .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-color: $k-gray;
  }
}

:deep(.open) {
  .dropdown-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .dropdown-menu {
    margin: 0;
    border: 1px solid $k-gray;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .menu-item-container {
      max-height: 400px;
    }
  }
}

.padded-container {
  width: 100%;
  padding: 10px;

  &.with-top-border {
    border-top: 1px solid $k-gray;
  }
}

.tag {
  padding: 0 4px 1px;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: $border-radius-small;

  &.gray {
    background: $medium-gray;
  }

  &.black {
    background: $k-darker-gray;
    color: $white;
  }
}

.check-container {
  width: 18px;
  display: inline-block;

  .check {
    padding-bottom: 3px;
  }
}

.options-section-container {
  padding-bottom: 15px;

  &:not(:nth-last-of-type(1)) {
    border-bottom: 1px solid $k-gray;
  }

  .section-option {
    padding: 4px 10px;
    color: $k-darker-gray;

    &:hover {
      background-color: $k-light-gray;
      cursor: pointer;
    }
  }
}
