
@import "stylesheets/scout/variables";

@media (min-width: $screen-sm-min) {
  // avoids the jarring experience during loading of new data
  .min-height-550 {
    min-height: 550px;
  }
}

.clear-panel {
  padding: 0;
  border: 0;
  line-height: 1.4;

  @media (min-width: $screen-sm-min) {
    padding: 20px 25px 20px 20px;
    border: 1px solid $k-light-gray;
    border-radius: $border-radius-large;
  }
}

.first-column {
  padding-bottom: 20px;
  border-bottom: 1px solid $k-light-gray;

  @media (min-width: $screen-sm-min) {
    padding-right: 20px;
    padding-bottom: 0;
    border-right: 1px solid $k-light-gray;
    border-bottom: 0;
  }
}

.content-header {
  border-bottom: 1px solid $medium-gray;
  padding-bottom: 10px;
  text-align: center;
}

:deep(.rating-bar-container) .range-bar {
  display: none;
}

:deep(.rating-circle) .layers {
  &.range-inner {
    background: $white !important;
  }

  &.range-outer {
    display: none;
  }
}

.no-hover:hover {
  color: inherit;
  text-decoration: underline;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  position: relative;

  &.negative-margin {
    top: -1px;
  }

  &.blue-dot {
    background-color: $blue-4;
  }

  &.purple-dot {
    background-color: $k-purple;
  }
}

.diamond {
  width: 8px;
  height: 8px;
  transform: rotate(-45deg);
  background-color: $pill-gray;
  border-radius: 1px;
  display: inline-block;
}

.grid-container {
  position: relative;
  padding-bottom: 30px;

  .category-wrapper {
    position: relative;
    z-index: 1;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  .background-grid {
    position: absolute;
    top: 0;
    left: calc(25% + 2.5px);
    width: calc(75% - 2.5px);
    height: calc(100% - 28px);
    background-image:
      repeating-linear-gradient(transparent 0 1px, transparent 1px 100%),
      repeating-linear-gradient(90deg, $k-light-gray 0 1px, transparent 1px 100%);
    background-size: 20% 100%;
    border-right: 1px solid $k-light-gray;
    z-index: 0;
  }

  .score-label {
    font-size: 12px;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  .x-axis-row {
    position: absolute;
    bottom: 0;
    left: calc(25% + 7.5px);
    width: calc(75% - 2.5px);
    height: 20px;
    text-align: right;
    font-size: 11px;
    line-height: 1.3;

    .x-axis-col {
      float: left;
      width: calc(100% / 5);
    }

    .x-axis-item {
      display: inline-block;
      width: 10px;
      cursor: pointer;
      text-align: center;
    }
  }
}
