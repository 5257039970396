
.location-header {
  height: 22px;
}

.nv-button-square-white {
  display: inline-block;
  width: 22px;
  height: 22px;
  padding: 3px;

  :deep(.base-icon) {
    vertical-align: text-top;
  }
}
