
@import "stylesheets/scout/variables";

.border-gradiant {
  background: linear-gradient(27deg, $newblue-4 32.7%, $darkblue-2 99.34%);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  border-top-left-radius: $border-radius-large;
  border-bottom-left-radius: $border-radius-large;
}
