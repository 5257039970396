
@import "stylesheets/scout/variables";

.icon-button {
  &:disabled, &:disabled .base-icon {
    opacity: 1;
  }
}

.declined {
  color: $scout-red;
  fill: $scout-red;
  background-color: $red-10;
  border-color: $scout-red;

  svg {
    fill: $scout-red;
  }
}
