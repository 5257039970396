
@import "stylesheets/globals/variables";

.file-select {
  margin: 0;
  font-weight: 400;

  &.dash-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 100%;
    text-align: center;
    border: 2px dashed $k-gray;
  }

  input[type="file"] {
    display: none;
  }
}
