
.check-option {
  margin-bottom: 0;
}

.continent-btn-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
}
