
.chart-legend {
  font-size: 12px;
}

.bullet-container {
  display: inline-block;
  width: 20px;
  line-height: 16px;

  .bullet {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50px;
  }
}

.name-container {
  display: inline-block;
  width: calc(100% - 25px);
  line-height: 16px;
}
