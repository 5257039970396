
@import "stylesheets/globals/variables";

.accordion-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.active {
    border-bottom: 1px solid $k-light-gray;
  }
}
