
@import "stylesheets/globals/variables";

.next-steps {
  position: relative;
  margin-bottom: 85px;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .next-steps-content {
    padding: 0 5px;

    .base-icon {
      width: 53px;
      height: 53px;
    }
  }

  .next-steps-separator {
    position: absolute;
    height: 55px;
    top: 110%;
    left: 50%;
    margin-left: -1px;
    border-left: 3px dotted $k-gray;

    @media (min-width: $screen-sm-min) {
      width: 61%;
      height: unset;
      top: 25px;
      left: 70%;
      margin-left: 0;
      border-top: 3px dotted $k-gray;
      border-left: 0;
    }

    @media (min-width: $screen-lg-min) {
      width: 70%;
      left: 65%;
    }
  }
}
