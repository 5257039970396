
@import "stylesheets/scout/variables";
@import "stylesheets/marketplace/variables";

.color-tag {
  border-radius: $border-radius-small;
  display: inline-block;
  padding: 2px 24px;
  width: fit-content;

  &.purple {
    background-color: $k-purple;
    color: $white;
  }

  &.light-purple {
    background-color: $marketplace-tag-bg-purple;
    color: $marketplace-tag-font-purple;
  }

  &.blue {
    border-color: $high-match-score-blue;
    background-color: $high-match-score-blue;
    color: $white;
  }

  &.padding-sm {
    padding: 3px 6px;
  }

  &.size-text-13px {
    font-size: 13px;
  }
}
