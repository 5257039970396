
.name-text {
  font-size: 17px;
}

.name-plate.compact {
  min-height: auto;

  .name-text {
    font-size: 13px;
    font-weight: 600;
  }
}
