
@import "stylesheets/globals/variables";

h1 {
  margin-top: 0;
}

ul {
  list-style: none;
  padding-inline-start: 0;

  li {
    display: flex;
    column-gap: 7px;

    .base-icon {
      fill: $k-dark-gray;
      width: 16px;

      &.success {
        fill: $k-green;
      }
    }
  }
}
