
div {
  line-height: 1.4;
}

hr {
  margin: 10px 0;
}

.panel-item {
  column-gap: 10px;
  display: flex;
  justify-content: space-between;
}

.list {
  list-style: none;
  padding-left: 15px;
  margin-bottom: 0;

  li {
    margin-top: 5px;
    padding-left: 10px;
    text-indent: -18px;

    &::before {
      content: "•";
      font-size: 12px;
      margin-right: 10px;
    }
  }
}
