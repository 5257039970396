
@import "stylesheets/scout/variables";

.count-container {
  position: absolute;
  top: 96px;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: 768px) {
    left: 100px;
  }

  .count-sum {
    font-size: 30px;
    line-height: 45px;
  }

  .count-desc {
    color: $k-dark-gray;
    font-size: 12px;
  }
}
