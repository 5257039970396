
@import "stylesheets/scout/variables";

.grid-container {
  position: relative;
  padding-bottom: 30px;

  .category-wrapper {
    position: relative;
    z-index: 1;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  .background-grid {
    position: absolute;
    top: 0;
    left: calc(25% + 2.5px);
    width: calc(75% - 2.5px);
    height: calc(100% - 28px);
    background-image:
      repeating-linear-gradient(transparent 0 1px, transparent 1px 100%),
      repeating-linear-gradient(90deg, $k-light-gray 0 1px, transparent 1px 100%);
    background-size: 33.33% 100%;
    border-right: 1px solid $k-light-gray;
    z-index: 0;
  }

  .score-label {
    font-size: 12px;
    overflow-wrap: break-word;
    hyphens: auto;
  }
}

.x-axis-row {
  display: flex;
  text-align: right;
  height: 20px;
  font-size: 11px;
  line-height: 1.3;

  .x-axis-col {
    float: left;
    width: calc(100% / 3);
  }

  .x-axis-item {
    display: inline-block;
    white-space: nowrap;
    width: 10px;
    cursor: pointer;
    text-align: center;
  }
}
