
@import "stylesheets/scout/variables";

.blue-bottom-border {
  border-bottom: 3px solid $newblue-6;
}

:deep(.icon) {
  width: 64px;
  height: 64px;
}

.icon-sm {
  width: 32px;
  height: 32px;
  padding-top: 7px;
}

.icon-lg {
  width: 64px;
  height: 64px;
}

.star-icon {
  width: 32px;
  height: 32px;
  stroke: $blue-4;
  fill: $newblue-9;
}

.card-outer {
  .primary-btn-blue, .secondary-btn-blue {
    width: 178px;

    @media (min-width: $screen-sm-min) {
      width: 140px;
    }
  }
}

.contact-wrapper {
  width: 100%;
  padding: 20px;
  border-radius: $border-radius-base;
  background: $bg-color;

  @media (min-width: $screen-sm-min) {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 15px 40px;
  }

  .contact-content {
    flex: 1 1 0;
  }
}
