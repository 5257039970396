
@import "stylesheets/scout/variables";

.messaging-button {
  position: absolute;
  height: 60px;
  top: 0;
  right: -25px;
  z-index: 2;

  button {
    height: 60px;
    padding: 20px 30px;
    border: 0;
    border-radius: 30px 0 0 30px;
    background-color: $k-darker-gray;
    color: $white;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;

    .base-icon {
      fill: $white;
    }
  }

  .unread-alert {
    position: absolute;
    top: -7px;
    left: 5px;
    min-width: 26px;
    height: 26px;
    padding: 4px;
    border-radius: 26px;
    background-color: $scout-red;
    color: $white;
    line-height: 18px;
    text-align: center;
  }
}
