
@import "stylesheets/scout/variables";

.notification-row {
  .notification-icon {
    position: relative;
    width: 22px;

    .base-icon {
      width: 20px;
      height: 20px;
      fill: $k-dark-gray;
    }

    &::after {
      display: block;
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      top: 1px;
      right: 1px;
      background-color: $scout-red;
      border-radius: 50%;
    }

    &.envelope::after {
      right: -2px;
    }
  }

  hr {
    border-color: $k-light-gray;
  }
}
