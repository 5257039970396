
@import "stylesheets/globals/variables";

.proposal-footer {
  margin: 50px 0;
  padding: 0 20px;

  svg {
    fill: $grey-4;
  }
}

.base-icon {
  width: 50px;
  height: 50px;
}
