
.bar-chart-wrapper {
  position: relative;
}

ul {
  z-index: 50;
  position: relative;

  li {
    margin: 15px 0;
    font-size: 11px;

    @media(min-width: 768px) {
      margin: 10px 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .item-name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;

      @media (min-width: 768px) {
        display: inline-block;
        width: 49%;
        text-align: right;
      }
    }

    .item-value {
      width: 100%;

      @media (min-width: 768px) {
        display: inline-block;
        width: 49%;
        margin-left: 5px;
      }

      .bar {
        height: 16px;
        display: inline-block;

        &:first-child {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }

        &:last-child {
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }
    }
  }
}

.percentage-panel-container {
  // TODO: Scout; make this display properly
  display: none;
  position: absolute;
  top: 0;
  left: 0;

  @media(min-width: 768px) {
    display: block;
    left: 48.5%;
  }
}
