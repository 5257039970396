
@import "stylesheets/globals/variables";

.fade-scroll-container {
  .fade-scrollable {
    max-height: 170px;
  }
}

.highlighted {
  :deep(strong) {
    color: $k-blue;
  }
}
