
@use "stylesheets/scout/variables" as *;

:deep(.svg-circle) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: $white;
  color: $newblue-5;
  flex-shrink: 0;

  .base-icon {
    stroke: $newblue-5;
  }
}

:deep(.next-steps-content) {
  background: $blue-10;
  border: 1px solid $newblue-9;
  padding: 20px 30px;
  border-radius: $border-radius-base;

  &.completed {
    background: $white;
    border: 1px solid $medium-gray;
    align-items: flex-start;

    .svg-circle {
      background: $blue-10;
    }
  }
}
