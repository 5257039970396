
hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-1 {
  flex: 1 1 0%;
}

.partner-label {
  margin-left: 28px;
}
