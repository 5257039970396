
.flex {
  align-items: center;
  display: flex;
  width: 100%;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }
}
