
@import "stylesheets/scout/variables";

.notice-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 20px;
  background: rgba($purple-5, .1);
  border-radius: $border-radius-base;
  border-left: 4px solid $purple-5;
}

.icon-sm {
  width: 20px;
  height: 20px;
}

.actions-wrapper {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;

  @media (min-width: $screen-sm-min) {
    padding: 20px;
  }

  @media (min-width: $screen-md-min) {
    padding: 30px;
  }
}

.footer-wrapper {
  display: flex;
  align-items: center;
  color: $purple-5;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;

  svg {
    margin-right: 3px;
    padding-top: 1px;
    fill: $purple-5;
  }
}
