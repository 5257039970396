
@import "stylesheets/scout/variables";

.subtext {
  border-bottom: 1px dashed $gray-3;
  color: $gray-3;
  font-size: 12px;
}

.section-wrapper {
  border-radius: $border-radius-large;
  padding: 10px 15px;
  border: 1px solid $k-gray;
}

.checked {
  border: 1px solid $blue-4;
  background: $blue-10;
}
