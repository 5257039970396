
@use "stylesheets/scout/variables" as *;

.mattters-to-add {
  border: 1px solid $gray-8;
  width: fit-content;
  padding: 5px 10px;
  border-radius: $border-radius-small;

  svg {
    position: relative;
    top: -1px;
    width: 15px;
    height: 15px;
    fill: $gray-1;
    opacity: 30%;
  }
}

.tooltip-icon {
  position: relative;
  top: -1px;
}

.connected-matter {
  padding: 15px 20px;
  border: 1px solid $gray-6;
  border-radius: $border-radius-large;
  background: $bg-color;
  position: relative;
}
