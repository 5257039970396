
.options-margin {
  margin-top: 10px;

  @media (min-width: 768px) {
    margin-left: 30px;
  }
}

.check-option {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.radio-option {
  display: block;

  &:not(:first-child) {
    margin-top: 10px;
  }

  @media (min-width: 768px) {
    display: inline;
    margin-top: 0;

    &:not(:first-child) {
      margin-left: 30px;
    }
  }
}

.menu-item-container {
  padding: 5px;

  li {
    padding: 5px 10px;
  }
}
