
@import "stylesheets/scout/variables";

.rating-bar-container {
  position: relative;

  .rating-fill {
    background: $high-match-score-blue;

    &.from-marketplace {
      background: $k-purple;
    }
  }

  .rating-bar, .rating-fill {
    position: relative;
    z-index: 1;

    .no-data-text {
      position: absolute;
      top: -6px;
      width: 100%;
      text-align: center;
      font-size: 11px;
      font-style: italic;
      color: $pill-gray;
    }

    .dot {
      position: absolute;
      top: 50%;
      right: 0;
      width: 8px;
      height: 8px;
      margin-left: 0.5px;
      transform: translate(-50%, -50%);
      background: $high-match-score-blue;
      border-radius: 50%;

      &.from-marketplace {
        background: $k-purple;
      }
    }
  }

  .range-bar {
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    height: 23px;
    background-color: $grey-3;
    border-radius: $border-radius-small;
    z-index: 0;
  }
}
