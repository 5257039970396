
.count-label {
  height: 40px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.4;
}

.count-content {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
