
@import "stylesheets/scout/variables";

.wrappable-tag {
  display: block;
  text-wrap: auto;
  width: fit-content;
}

.scout-tag-row {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}

.scout-tag-editing-col {
  width: 100%;
  padding: 15px 10px;
  border: 1px solid $k-gray;
  border-radius: $border-radius-base;
  line-height: 1.3;

  @media (min-width: $screen-sm-min) {
    padding: 15px 20px 15px 15px;
  }
}
