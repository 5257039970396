
@import "stylesheets/scout/variables";

.response-group {
  border-top: 1px solid $medium-gray;

  &:last-child {
    border-bottom: 1px solid $medium-gray;
  }

  .response-group-title {
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    user-select: none;

    &:hover .response-group-title-text {
      text-decoration: underline;
    }

    &.disabled {
      cursor: default;
      opacity: .5;

      &:hover .response-group-title-text {
        text-decoration: none;
      }
    }
  }
}
