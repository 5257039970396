
@use "stylesheets/globals/variables";

.upload-file-btn {
  padding: 11px 24px;
  height: auto;
}

.selected-file {
  padding: 5px 15px;
  border-radius: 25px;
  border: 1px solid variables.$medium-gray;
  max-width: 100%;
  width: fit-content;
  height: fit-content;
}
