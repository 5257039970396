
@import "stylesheets/scout/variables";

.section-header {
  border-bottom: 1px solid $k-light-gray;
  font-weight: 700;
  margin-bottom: 5px;
  padding-bottom: 5px;
  text-transform: uppercase;
}
