
@import "stylesheets/scout/variables";

.big-radio-button {
  background: $grey-1;
  border: 1px solid $grey-4;
  border-radius: $border-radius-large;
  padding: 20px;
  line-height: 1.4;

  &:hover {
    border-color: rgba($grey-4, .6);
  }

  &.selected {
    background: $white;
  }
}

.button-face {
  display: flex;
  align-items: center;
  column-gap: 20px;
  cursor: pointer;
}

.expanded-content {
  @media (min-width: $screen-sm-min) {
    padding-left: 33px;
  }
}
