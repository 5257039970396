
@import "stylesheets/globals/variables";

.missing-photo {
  box-shadow: 0 0 8px 0 rgba($black, .1);
  display: inline-block;
  width: 60px;
  height: 60px;
  padding: 23px 0;
  border-radius: 50%;
  background-color: $bg-color;
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  cursor: default;
  user-select: none;

  &.larger {
    font-size: 18px;
    font-weight: 600;
    height: 80px;
    line-height: 20px;
    padding: 29px 0;
    width: 80px;
  }

  &.medium {
    width: 50px;
    height: 50px;
    padding: 19px 0;
    font-size: 12px;
    line-height: 12px;
  }

  &.small {
    width: 40px;
    height: 40px;
    padding: 14px 0;
    font-size: 11px;
    line-height: 12px;
  }

  &.smallest {
    width: 30px;
    height: 30px;
    padding: 9px 0;
    font-size: 8px;
    line-height: 11px;
  }
}
