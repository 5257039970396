
@import "stylesheets/scout/variables";

.hr {
  margin: 5px 0;
}

.menu-item-container {
  padding: 5px;

  li {
    padding: 5px 10px;
  }
}

.percent-input-container {
  align-items: center;
  border: 1px solid $k-light-gray;
  border-radius: $border-radius-base;
  display: flex;
}

.percent-input {
  border: 0;
}
