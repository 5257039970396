
.toggle-container {
  position: relative;
}

.toggle-button {
  &:disabled {
    opacity: .5;
    pointer-events: none;
  }
}

.toggle-title {
  padding-left: 10px;
}
