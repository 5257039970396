
@import "stylesheets/scout/variables";

.checkmark {
  margin-top: 16px;
  width: 20px;
  height: 20px;
  fill: $white;
}

.notice-modal .notice-header {
  height: auto;
}

.reviewable-lawyers {
  border-top: 1px solid $k-light-gray;
  border-bottom: 1px solid $k-light-gray;
  padding: 20px 0;

  @media (min-width: 768px) {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  li .row { padding: 0 20px; }
}
