
@import "stylesheets/scout/variables";

.scout-tag {
  &.blue {
    .base-icon {
      fill: $tag-blue;
    }
  }

  &.gray {
    .base-icon {
      fill: $tag-gray;
    }
  }

  &.green {
    .base-icon {
      fill: $tag-green;
    }
  }

  &.purple {
    .base-icon {
      fill: $tag-purple;
    }
  }

  &.red {
    .base-icon {
      fill: $tag-red;
    }
  }

  &.yellow {
    .base-icon {
      fill: $tag-yellow;
    }
  }
}
