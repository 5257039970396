
@import "stylesheets/scout/variables";

.arrow-hidden {
  fill: transparent
}

.arrow-gray-hover {
  fill: $pill-gray;
}

.column-header {
  cursor: pointer;
}

.light-gray-background {
  // Fix bug in Firefox & Safari; redundant in Chromium browsers
  background-color: transparent
}
