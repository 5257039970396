
@import "stylesheets/scout/variables";

.provision-rfq-access-wrapper {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  .provision-rfq-access {
    padding: 20px 30px;
    background: $gray-8;
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $screen-sm-min) {
      padding: 40px 64px;
    }

    .icon {
      display: flex;
      background: $newblue-10;
      border-radius: 100px;
    }

    .icon-lg {
      width: 64px;
      height: 64px;
    }
  }

  .provision-rfq-access-text {
    padding: 0 0 5px;
    margin-top: 20px;
    color: $gray-1;
    font-size: 15px;
    font-weight: 700;
    word-wrap: break-word;

    @media (min-width: $screen-sm-min) {
      padding: 0 20px 5px;
      text-align: center;
    }
  }

  .request-access-text {
    color: $gray-1;
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
  }
}

.contact-title {
  border-top: 1px solid $gray-8;
  border-bottom: 1px solid $gray-8;
  padding-top: 10px;
  padding-bottom: 10px;
}

.icon-wrapper {
  position: relative;
  width: 22px;

  .base-icon {
    width: 18px;
    height: 18px;
    fill: $k-dark-gray;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    top: 0;
    right: -4px;
    border-radius: 50%;
    background-color: $scout-red;
  }
}
