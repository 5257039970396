
@import "stylesheets/scout/variables";

.list-item.matter-row:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.resource-list.static-list .list-item {
  padding: 20px 30px;
  border-bottom-color: $k-gray;

  &.expanded {
    padding-left: 25px;
  }
}

.full-width {
  width: 100%
}
