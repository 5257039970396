
@import "stylesheets/scout/variables";

.brand-icon {
  max-width: 125px;
}

.title-logo {
  .brand-icon {
    max-width: 110px;
  }
}

.display-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.secondary-btn-blue.smaller {
  width: fit-content;
  padding: 10px 30px;
  white-space: nowrap;
}

.data-disclaimer {
  padding: 15px 30px;
  background: white;
  border-radius: $border-radius-large;

  > [class*='col-'] {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
