
@import "stylesheets/scout/variables";

.blue-accent {
  background-color: $blue-10;
  padding: 20px 30px 30px 30px;
  border-radius: $border-radius-large;
  margin-bottom: 30px;
  position: relative;

  .top-label {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }
}
