
@import "stylesheets/scout/variables";

.selection-badge {
  display: inline-block;
  padding: 11px 15px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 20px;
  border-radius: $border-radius-base;

  &.selected {
    color: $scout-green;
    background-color: rgba($scout-green, .1);

    .base-icon {
      fill: $scout-green;
    }
  }

  &.declined {
    color: $scout-red;
    background-color: rgba($scout-red, .1);

    .base-icon {
      fill: $scout-red;
    }
  }
}
