
@import "stylesheets/globals/variables";

.navbar.lawyer-application-nav {
  margin-bottom: 0;
  border-bottom: 1px solid $k-gray;
  background-color: $white;
  box-shadow: none;

  .nav-container {
    padding-right: 30px;
    padding-left: 30px;
  }

  .navbar-header {
    .navbar-brand {
      height: 70px;
      padding: 23px 16px;

      .brand-icon {
        display: inline-block;
        width: 120px;
        height: 24px;
        vertical-align: middle;
        fill: $k-darker-gray;
      }
    }

    .navbar-toggle {
      margin: 24px 0;
      padding: 6px;

      .icon-bar {
        background-color: $body-text-color;
      }
    }
  }

  .navbar-nav {
    display: flex;
    align-items: center;
    padding-right: 15px;

    & > li > a {
      padding: 25px 15px;
      color: $body-text-color;
      font-size: 13px;
      font-weight: 600;
      text-decoration: none;

      &:hover, &:active, &:focus {
        opacity: .75;
        background-color: transparent;
      }
    }
  }
}

.padding-because-fixed-nav {
  padding-top: 71px;
}

.k-footer {
  background-color: $k-lighter-gray;
}
