
@import "stylesheets/globals/variables";

.scout-rfp-badge {
  display: block;
  height: 22px;
  margin-top: 1px;
  border: 1px solid $high-match-score-blue;
  border-radius: $border-radius-small;
  padding: 3px 6px;
  font-size: 13px;
  line-height: 14px;
  font-weight: 700;
  background-color: $high-match-score-blue;
  color: $white;
}
