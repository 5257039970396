
@import "stylesheets/scout/variables";

.comparison-header {
  border-bottom: 1px solid $gray-7;
  font-weight: 600;
  padding-bottom: 4px;
}

.a-form .check-option.vertical {
  margin-bottom: 15px;
}
