
@import "stylesheets/globals/variables";

:deep(.button-column) {
  @media (min-width: $screen-sm-min) {
    width: 48%;
  }

  @media (min-width: $screen-lg-min) {
    width: 38%;
  }
}

:deep(.files-column) {
  @media (min-width: $screen-sm-min) {
    width: 52%;
  }

  @media (min-width: $screen-lg-min) {
    width: 62%;
  }
}
