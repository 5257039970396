
@import "stylesheets/scout/variables";

.scout-review-item {
  padding: 10px;
  min-height: 65px;
  border: 1px solid $k-light-gray;
  border-radius: $border-radius-large;

  @media (max-width: 767px) {
    > div:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
