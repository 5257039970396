
@import "stylesheets/globals/variables";

.proposal-lawyer-details {
  @media (min-width: $screen-sm-min) {
    display: flex;
  }
}

.main-details {
  &.right-padded {
    @media (min-width: $screen-sm-min) {
      padding-right: 20px;
    }

    @media (min-width: $screen-md-min) {
      padding-right: 30px;
    }
  }
}

.lawyer-details-sidebar-container {
  @media (min-width: $screen-sm-min) {
    border-left: 1px solid $k-light-gray;
  }

  .lawyer-details-sidebar {
    border: 0;

    @media (min-width: $screen-sm-min) {
      padding: 0 20px;
    }

    @media (min-width: $screen-md-min) {
      padding: 0 30px;
    }
  }
}

.lawyer-details-sidebar-item {
  display: flex;
  column-gap: 15px;
  padding: 12px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}
