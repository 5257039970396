
@import "stylesheets/scout/variables";

.quote-container {
  border: 1px solid $medium-gray;
  border-radius: $border-radius-base;
  padding: 10px 15px;

  &.received-scout {
    border-left: 3px solid $high-match-score-blue;
  }

  &.received-marketplace {
    border-left: 3px solid $k-purple;
  }

  &.pending {
    border-left: 3px solid $scout-gray;

    .quote-status {
      color: $pill-gray;
    }
  }

  &.declined {
    border-left: 3px solid $scout-red;

    .quote-status {
      color: $scout-red;
    }
  }
}

.updated {
  height: 6px;
  width: 6px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: $p-yellow;
}

.icon-wrapper {
  position: relative;
  width: 22px;

  .base-icon {
    width: 18px;
    height: 18px;
    fill: $k-dark-gray;
  }

  &::after {
    display: block;
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    top: 1px;
    right: -4px;
    background-color: $scout-red;
    border-radius: 50%;
  }
}
