
@import "stylesheets/globals/variables";

.header-link {
  color: $k-dark-gray;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;

  &:first-child {
    margin-right: 15px;
  }

  &:hover {
    text-decoration: underline;
  }

  &.selected {
    color: $k-darker-gray;
    font-weight: 600;
  }
}
