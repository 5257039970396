
@import "stylesheets/globals/variables";
@import "stylesheets/globals/mixins";

.filter-button-container {
  .dropdown-menu {
    display: block;
    width: 100%;
    visibility: hidden;
    margin: 0;
    padding: 20px;
    border: 0;
    border-radius: $border-radius-large;
    @include k-shadow;
  }

  &.open .dropdown-menu {
    visibility: visible;
  }

  .base-icon {
    &.dot {
      fill: $white;
      width: 5px;
      height: 5px;
    }
  }
}
