
@import "stylesheets/globals/variables";

.section-wave-top {
  margin-top: -1px;
  padding: 42px 10px 0;
  color: $white;
  text-align: center;
  height: 200px;

  @media (min-width: $screen-sm-min) {
    padding: 42px 0 0;
    height: 140px;
  }
}

.section-wave-bottom {
  height: 175px;
  margin-top: -4px;
  background-image: url("images/wave_header_bottom.svg");
  background-size: 100% 100%;

  @media (min-width: $screen-md-min) {
    height: 250px;
  }
}
