
@import "stylesheets/scout/variables";

.hr {
  margin: 5px 0;
}

.d-flex {
  flex-wrap: wrap;

  @media (min-width: 768px) {
    align-items: baseline;
    flex-wrap: nowrap;
  }

  div {
    flex-basis: 100vw;

    @media (min-width: 768px) {
      flex-basis: 0;
    }
  }
}

.remove-button-container {
  align-self: flex-start;
  padding-top: 10px;

  @media (min-width: 768px) {
    padding-top: 35px;
  }
}

.menu-item-container {
  padding: 5px;

  li {
    padding: 5px 10px;
  }
}

.hidden {
  visibility: hidden;
}

.radio-container {
  @media (min-width: 768px) {
    margin-top: 10px;
  }
}
