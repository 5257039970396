
@import "stylesheets/scout/variables";

.status-badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 8px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: $border-radius-base;
  flex-shrink: 0;

  &.new-rfq {
    background-color: $k-green;
    color: $white;
  }

  &.draft {
    background-color: $mid-match-score-blue;
    color: $white;
  }

  &.submitted {
    background-color: $k-blue;
    color: $white;
  }

  &.selected {
    background-color: $k-blue;
    color: $white;
  }

  &.declined {
    background-color: $k-purple;
    color: $white;
  }

  &.client-declined {
    background-color: $k-purple;
    color: $white;
  }

  &.client-closed {
    background-color: $k-purple;
    color: $white;
  }
}
