
@import "stylesheets/scout/variables";

.quote-compare-wrapper {
  padding: 30px 55px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;

  &.blue {
    background: $high-match-score-blue;
  }

  &.purple {
    background: $k-purple;
  }
}
