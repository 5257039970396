
@import "stylesheets/globals/variables";

.navbar.proposal-nav {
  border-radius: 0;
  box-shadow: none;
  color: $white;

  .navbar-content {
    display: block;
    text-align: center;

    @media (min-width: $screen-sm-min) {
      align-items: center;
      display: flex;
      margin: auto;
      max-width: 1140px;
      padding: 0 30px;
      text-align: unset;
    }

    @media (min-width: $screen-lg-min) {
      padding: 0;
    }
  }

  .navbar-brand {
    padding: 25px 0;
    width: 100%;

    .brand-icon {
      fill: $white;
    }
  }

  .navbar-header {
    padding: 10px 0;
  }

  .project-name {
    font-weight: 700;
    font-size: 24px;
    text-align: center;

    @media (min-width: $screen-sm-min) {
      text-align: right;
    }
  }
}
