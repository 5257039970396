
@import "stylesheets/scout/variables";

.rounded-frame {
  border: 1px solid $grey-3;
  border-radius: $border-radius-large;
  padding: 5px 20px 20px;

  @media (min-width: $screen-sm-min) {
    padding: 5px 30px 20px;
  }
}

.base-icon {
  fill: $k-blue;
  height: 13px;

  &.right-3 {
    margin-right: 3px;
  }

  &.right-2 {
    margin-right: 2px;
  }
}

.options {
  margin-bottom: 15px;
  font-size: 12px;

  @media (min-width: $screen-sm-min) {
    position: relative;
    top: 20px;
    margin-bottom: 0;
  }
}
