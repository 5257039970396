
@import "stylesheets/globals/variables";

:deep {
  .missing-photo.smallest {
    padding: 0;
  }
}

.request-possible-lawyer {
  border-bottom: 1px solid $k-gray;
  min-height: 60px;
  padding: 10px 0px;

  &:last-child {
    border-bottom: none;
  }
}

.strikethrough {
  text-decoration: line-through;
}

.rfp-badge {
  display: flex;
  min-height: 26px;
  border: 1px solid $medium-gray;
  border-radius: $border-radius-small;
  align-items: center;
  padding: 3px 6px;
  font-size: 12px;
  line-height: 14px;
}

.green-badge {
  border-color: $k-green;
}

.red-badge {
  border-color: $k-red;
  color: $k-red;
}

.gray-badge {
  border-color: $k-dark-gray;
}

.no-shrink {
  flex-shrink: 0;
}
