
.sort-icon {
  margin-left: 4px;
  width: 12px;
  height: 12px;
}

:deep(.dropdown-menu) {
  width: fit-content;

  li:first-child {
    padding-top: 5px;
  }

  li:last-child {
    padding-bottom: 5px;
  }

  li {
    padding: 0 30px;

    &.selected {
      padding-left: 13px;

      a {
        font-weight: 600;
      }
    }

    a {
      border: 0;
    }
  }
}
