
@import "stylesheets/scout/variables";

.missing-photo {
  box-shadow: 0 0 8px 0 rgba($black, .1);
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $bg-color;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  cursor: default;
  user-select: none;
  vertical-align: middle;

  &.with-icon {
    padding: 0;
  }

  &.larger {
    width: 80px;
    height: 80px;
    font-size: 30px;
    line-height: 30px;
  }

  &.medium {
    width: 50px;
    height: 50px;
  }

  &.small {
    width: 40px;
    height: 40px;
    font-size: 17px;
    line-height: 18px;
  }

  &.smallest {
    width: 30px;
    height: 30px;
    font-size: 8px;
    font-weight: 600;
    line-height: 11px;
  }
}
