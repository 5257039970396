
@import "stylesheets/scout/variables";

.success-message {
  background-color: $k-light-green-bg;

  margin-top: 30px;
  margin-bottom: 30px;
  padding: 10px 20px;
  border-radius: $border-radius-large;
  border-left: 6px solid $green-4;

  svg {
    fill: $green-4;
    margin-right: 12px;
  }
}

.scrollable-notes {
  padding: 20px;
  max-height: 300px;
  overflow-x: hidden;
  border: 1px solid $k-gray;
  border-radius: $border-radius-large;
}

.base-icon.delete-btn {
  width: 12px;
  height: 12px;
  margin-bottom: 3px;
  fill: $k-blue;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $k-gray;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

@supports (not selector(::-webkit-scrollbar)) {
  html {
    scrollbar-width: thin;
    scrollbar-gutter: stable;
    scrollbar-color: transparent;
  }
}

:deep(.faded-section.fade-down)::after {
  background: linear-gradient(180deg, rgba($white, 0) 0%, $k-lighter-gray 100%);
}
