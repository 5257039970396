
.category-wrapper:not(:last-child) {
  margin-bottom: 15px;
}

.category-name {
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .category-wrapper {
    align-items: center;
    display: flex;
  }

  .category-name {
    margin-bottom: 0;
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    width: 220px;
  }

  .rating-bar-container {
    margin-left: 15px;
    width: calc(100% - 205px);
  }
}
